<div nz-row [nzGutter]="8">
  <div nz-col [nzSpan]="12">
    <h6>{{user?.account?.name}} - {{user?.account?.id}}</h6>
  </div>
</div>
<ng-template [ngIf]="user?.subRole?.type === 'ADMINISTRATOR'">
  <ng-template [ngIf]="user?.canManageOrders">
    <button nz-button nzType="primary" [routerLink]="['/dashboard', { outlets: { dashboard: ['create-order'] } }]">Create Order</button>
  </ng-template>
  <button nz-button nzType="primary" [class]="user.canManageOrders ? 'ml-2' : null" (click)="createModal()">Create Project</button>
  <button nz-button nzType="primary" class="ml-2" (click)="createInviteProjectOwnerModal()">Invite Project Owner</button>
</ng-template>

<div style="margin-top: 10px;">
  <mat-form-field style="width: 110px;">
    <mat-label>Project</mat-label>
    <mat-select (selectionChange)="updateProject($event)">
      <mat-option value="all">All</mat-option>
      <mat-option *ngFor="let project of projects" [value]="project.id">{{project.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="width: 110px;" class="ml-2">
    <mat-label>Stage</mat-label>
    <mat-select [disabled]="disableStage" (selectionChange)="updateStage($event)">
      <mat-option value="all">All</mat-option>
      <mat-option *ngFor="let stage of stages" [value]="stage.id">{{stage.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button nz-button nzType="primary" class="ml-2" (click)="filterOrders()">View Orders</button>
  <ng-template [ngIf]="user?.subRole?.type !== 'USER'">
    <button nz-button nzType="primary" class="ml-2" (click)="navigateToProjectStatus()">View Project Status</button>
  </ng-template>
  <!-- <button nz-button nzType="primary" class="ml-2" (click)="resetOrders()">Reset Orders</button> -->
</div>

<!-- <div style="margin-top: 10px;">
  <nz-table #orderTable [nzData]="orders" [nzLoading]="showLoading">
    <thead>
      <tr>
        <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 10;"><strong>Contract No</strong></th>
        <ng-template [ngIf]="user?.role?.type !== 'SUPPLIER'">
          <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 9;"><strong>Account No</strong></th>
          <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 8;"><strong>Project Cost Centre Code</strong></th>
        </ng-template>
        <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
          <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 9;"><strong>Ordering Company</strong></th>
        </ng-template>
        <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 7;"><strong>Order No</strong></th>
        <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 6;" [nzFilters]="projectListOfFilters" [nzFilterFn]="projectFilter"><strong>Project</strong></th>
        <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;" [nzFilters]="stageListOfFilters" [nzFilterFn]="stageFilter"><strong>Stage</strong></th>
        <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;"><strong>No. of Items</strong></th>
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;"><strong>Total Price</strong></th>
        <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;"><strong>Destination</strong></th>
        <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;"><strong>Created On</strong></th>
        <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 1;"><strong>Status</strong></th>
        <th style="background: #004b62; color: white;"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orderTable.data let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
        <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.contract_no }}</td>
        <ng-template [ngIf]="user?.role?.type !== 'SUPPLIER'">
          <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.account_no }}</td>
          <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.project_cost_centre_code }}</td>
        </ng-template>
        <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
          <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.createdBy?.account?.name }}</td>
        </ng-template>
        <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><a [routerLink]="['/dashboard', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a></td>
        <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.project?.name }}</td>
        <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.stage?.name }}</td>
        <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.assets?.length }}</td>
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">£{{ order?.agreed_price | number: '1.2-3' }}</td>
        <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.destination }}</td>
        <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.createdOn | date: 'yyyy-MM-dd, h:mm a' }}</td>
        <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><div class="circle" [ngClass]="calculateStatus(order?.assets)"></div></td>
        <td><button nzTooltipTitle="delete" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip (click)="delete(order?.id)"><i nz-icon nzType="delete"></i></button></td>
        <td>
          <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['track', order?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
          <button nzTooltipTitle="Pay" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['payment', order?.id] } }]"><fa-icon icon="money-bill"></fa-icon></button>
          <button nzTooltipTitle="Provenance" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['provenance', order?.id] } }]"><fa-icon icon="tree"></fa-icon></button>
          <ng-template [ngIf]="user?.subRole?.type !== 'USER'">
            <button nzTooltipTitle="Linked Orders" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['linked-orders', order?.id] } }]"><fa-icon icon="link"></fa-icon></button>
          </ng-template>
          <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
            <button nzTooltipTitle="Upload Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-upload" (click)="openUploadDocumentDialog(order)"></fa-icon></button>
          </ng-template>
          <ng-template [ngIf]="user?.subRole?.type !== 'USER' && user?.role?.type !== 'SUPPLIER'">
            <button nzTooltipTitle="Download Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-download" (click)="openUploadedDocumentsDialog(order)"></fa-icon></button>
          </ng-template> -->
          <!-- <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
            <button nzTooltipTitle="Create Requisition" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['create-requisition'] } }]" [queryParams]="{projectId: order?.project}"><fa-icon icon="plus-square"></fa-icon></button>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div> -->

<div style="margin-top: 10px;">
  
  <!-- <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
  </mat-form-field> -->
  
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  
    <!-- Position Column -->
    <ng-container matColumnDef="contract_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract No. </th>
      <td mat-cell *matCellDef="let order"> {{order.contract_no}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="account_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account No </th>
      <td mat-cell *matCellDef="let order"> {{order.account_no}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="project_cost_centre_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost Centre </th>
      <td mat-cell *matCellDef="let order"> {{order.project_cost_centre_code}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="order_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No </th>
      <td mat-cell *matCellDef="let order"> {{order.order_no}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project </th>
      <td mat-cell *matCellDef="let order"> {{order.project.name}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="stage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Stage </th>
      <td mat-cell *matCellDef="let order"> {{order.stage.name}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="assets">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No. of Items </th>
      <td mat-cell *matCellDef="let order" style="text-align: center"> {{order.assets?.length}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="agreed_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Price </th>
      <td mat-cell *matCellDef="let order"> £{{ order?.agreed_price | number: '1.2-3' }} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="destination">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center">Destination</th>
      <td mat-cell *matCellDef="let order" style="text-align: center"> <img [matTooltip]="order?.destination + ' (click to copy)'" matTooltipPosition="above" (click)="copyToClipboard(order?.destination)" src="assets/img/location-question.png" style="width: 1.25em"></td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
      <td mat-cell *matCellDef="let order"> {{ order?.createdOn | date: 'yy-MM-dd, HH:mm' }} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let order"> <div class="circle" [ngClass]="calculateStatus(order?.assets)"></div> </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td mat-cell *matCellDef="let order">
        <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['track', order?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
        <button nzTooltipTitle="Pay" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['payment', order?.id] } }]"><fa-icon icon="money-bill"></fa-icon></button>
        <button disabled nzTooltipTitle="Provenance" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['provenance', order?.id] } }]"><fa-icon icon="tree"></fa-icon></button>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER'">
          <button nzTooltipTitle="Linked Orders" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['linked-orders', order?.id] } }]"><fa-icon icon="link"></fa-icon></button>
        </ng-template>
        <!-- <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
          <button nzTooltipTitle="Upload Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-upload" (click)="openUploadDocumentDialog(order)"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER' && user?.role?.type !== 'SUPPLIER'">
          <button nzTooltipTitle="Download Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-download" (click)="openUploadedDocumentsDialog(order)"></fa-icon></button>
        </ng-template> -->
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<!-- <div style="margin-top: 10px;">
  <nz-table [nzData]="projects" [nzLoading]="showLoading">
    <thead>
      <tr>
        <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;"><strong>Project Name</strong></th>
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;"><strong>Stages</strong></th>
        <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;"><strong>Description</strong></th>
        <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;"><strong>Owner</strong></th>
        <th style="background: #4c6369; color: white; box-shadow: 5px 0 5px -2px #4c6369; z-index: 1;"><strong>Last Updated</strong></th>
        <th style="background: #004b62; color: white;"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let project of projects; let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
        <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ project?.name }}</td>
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ project?.stages?.length }}</td>
        <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ project?.description }}</td>
        <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ project?.ownerName }}</td>
        <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ project?.updatedOn | date: 'yyyy-MM-dd, h:mm a' }}</td>
        <td>
          <ng-template [ngIf]="user?.canTrackOrders">
            <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: [ 'project', project?.id ] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div> -->
