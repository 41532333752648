<mat-form-field appearance="fill" fxFlexFill>
    <mat-label>{{label}}</mat-label>
    <input matInput type="text" [ngModel]="inputFieldFormControl" (ngModelChange)="onInputChange($event)" [matAutocomplete]="auto" />

    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
    <mat-option *ngFor="let option of searchOptions | async" [value]="option.shortAddress" 
        (onSelectionChange)="optionSelectionChange(option, $event)"
        [matTooltip]="option.fullAddress" matTooltipShowDelay="1000">
        <span class="mat-body">{{ option.shortAddress }}</span>
    </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="error">Please provde a {{label}}!</mat-error>
</mat-form-field>