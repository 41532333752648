<div nz-row>
  <div nz-col nzSpan="12" style="background: url(assets/img/login.jpg) no-repeat; background-size: cover; height: 100vh;">
    <!-- <img src="assets/img/login.png" alt=""> -->
    <div fxLayout="column" fxLayoutAlign="center center">
      <div fxLayoutAlign="center center" style="margin-top: 50vh;">
        <img src="assets/img/TrackChainLogo.png" alt="Track Chain!">
      </div>
      <div fxLayoutAlign="center center">
        <h2 nz-typography style="color: white;">Welcome to TrackChain</h2>
      </div>        
    </div>
  </div>
  <div nz-col nzSpan="12">
    <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
      <h2 nz-typography>Hello! Welcome back.</h2>
      <span nz-typography>Log in with your data that you entered during Your registration.</span>
      <nz-form-item style="margin-top: 15px;">
        <nz-form-control nzErrorTip="Please input your email!">
          <nz-input-group [nzPrefix]="envelopeIcon">
            <input type="text" nz-input formControlName="email" placeholder="Email" />
          </nz-input-group>
          <ng-template #envelopeIcon>
            <fa-icon [icon]="['far', 'envelope']"></fa-icon>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <!-- <nz-input-group nzPrefixIcon="lock">
            <input type="password" nz-input formControlName="password" placeholder="Password" />
          </nz-input-group> -->
          <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
            <input
              [type]="passwordVisible ? 'text' : 'password'"
              nz-input
              placeholder="Password"
              formControlName="password"
            />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <!-- <div nz-row class="login-form-margin">
        <div nz-col [nzSpan]="12">
          <label nz-checkbox formControlName="remember">
            <span>Remember me</span>
          </label>
        </div>
        <div nz-col [nzSpan]="12">
          <a class="login-form-forgot">Forgot password</a>
        </div>
      </div> -->
      <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Start now!</button>
      Don't have an account? <a routerLink="/signup"> Sign up </a>
    </form>

    <nz-footer style="background: transparent; margin-top: 10vh;">
      <div class="text-right">
        <span class="text-muted"><span>
          <!-- <img src="assets/img/TrackChainBlack1.png" alt="Track Chain!"> -->
        </span></span>
      </div>
    </nz-footer>
  </div>
</div>