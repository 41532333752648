import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

import { FlexLayoutModule } from '@angular/flex-layout';

import { LoginComponent } from './login.component';

import { ComponentsModule } from '../../components/';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';


const modules = [
  LoginComponent
];


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    FlexLayoutModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzCheckboxModule,
    NzLayoutModule,
    NzIconModule,
    ComponentsModule
  ],
  providers: [
  ],
  declarations: modules,
  exports: modules
})
export class LoginModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faEnvelope);
  }
}
