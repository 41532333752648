<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="data.links.length > 0">
    <div class="col-sm-12 mb-3" *ngFor="let link of data.links">
      <a nz-button download target="_blank" [href]="link" nzType="link">
        <fa-icon [icon]="['fas', (link?.split('.')[4] === 'docx' || link?.split('.')[4] === 'doc' || link?.split('.')[4] === 'dto') ? 'file-word' : (link?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="3x"></fa-icon>
      </a>
      {{extractFilename(link)}}
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
