import { Component, OnInit, OnDestroy, ViewContainerRef, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ProjectService } from '@plasma/services/project.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { StageService } from '@plasma/services/stage.plasma';
import { PhaseService } from '@plasma/services/phase.plasma';
import { OrderService } from '@plasma/services/order.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { EmailService } from '@plasma/services/email.plasma';

import { IAgent } from '@plasma/models/agent';
import { IProject } from '@plasma/models/project';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { LoadingService } from '@plasma/components/loading/loading.service';

import { CreatePhaseModalComponent } from '@plasma/components/modal/create-phase-modal.component';
import { AssignUsersModalComponent } from '@plasma/components/modal/assign-users-modal.component';
import { ImportOrdersModalComponent } from '@plasma/components/modal/import-orders-modal.component';
import { UploadedDocumentsDialogComponent } from '@plasma/components/dialog/uploaded-documents.component';

import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  id: string;
  user: IAgent;
  project: any;
  phases: any[];
  stages: any[];
  accountAssociatedUsers: IAgent[];
  showLoading = false;

  constructor(private projectService: ProjectService,
              private stageService: StageService,
              private phaseService: PhaseService,
              private orderService: OrderService,
              private authService: AuthService,
              private agentService: AgentsService,
              private emailService: EmailService,
              private modal: NzModalService,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private viewContainerRef: ViewContainerRef,
              private loadingService: LoadingService,
              public dialog: MatDialog,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.authService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IAgent) => {
        console.log('user/agent', user);
        this.user = user;
        this.loadStages();
        this.loadAccountAssociatedUsers();
    });

    this.loadProject();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadProject(): void {
    this.projectService.get(this.id)
      .subscribe((project) => {
        this.project = project;
        this.phases = project.phases;
        console.log('project', project);
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
    });
  }

  loadStages(): void {
    this.showLoading = true;
    this.stageService.getByProject(this.id)
      .subscribe((stages) => {
        this.stages = stages;
        console.log('stages', stages);
        this.showLoading = false;
      }, (error) => {
        this.showLoading = false;
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error}`);
      });
  }

  createModal(): void {
    const modal = this.modal.create({
      nzTitle: 'Create Phase',
      nzContent: CreatePhaseModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 'max-content',
      nzComponentParams: {
        user: this.user,
        project: this.project.id,
      },
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: [
        {
          label: 'Create Phase',
          onClick: componentInstance => {
            componentInstance.createPhase();
          }
        }
      ]
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.createPhase(result));
  }

  createPhase(phase: any): void {
    if (!phase) {
      return;
    }
    this.loadingService.start();
    this.phaseService.create(phase)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.loadingService.complete();
        this.message.create('success', `Phase was created successfully!`);
        this.loadProject();
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error ${error.error.error}`);
    });
  }

  calculateAssetStatus(deliveryDate, floatDays) {
    if (deliveryDate === 0) {
      return;
    }

    const criticalDate = moment(deliveryDate).add(floatDays, 'days');
    if (moment().isSameOrBefore(deliveryDate)) {
      return 0;
    } else if (moment().isAfter(deliveryDate) && moment().isSameOrBefore(criticalDate)) {
      return 1;
    } else if (moment().isAfter(criticalDate)) {
      return 2;
    }
  }

  calculateStatus(assets: any[]) {
    if (!assets) {
      return;
    }
    const statusVal = assets.map((asset) =>
      this.calculateAssetStatus(asset.planned_dates.planned_delivery_date, asset.float_days))
      .reduce((max, status) => (status > max ? status : max), 0);

    switch (statusVal) {
      case 0:
        return 'green';
        break;
      case 1:
        return 'yellow';
        break;
      case 2:
        return 'red';
        break;

      default:
        break;
    }
  }

  loadAccountAssociatedUsers(): void {
    if (!this.user.account.id) {
      return;
    }

    this.agentService.getByAccountAndSubRole(this.user.account.id, 'USER')
      .subscribe((agents) => {
        this.accountAssociatedUsers = agents;
        console.log('agents', this.accountAssociatedUsers);
      }, (error) => {
        console.error('Error', error.error.error);
    });
  }

  createAssignUsersModal(modalTitle: TemplateRef<{}>) {
    const modal = this.modal.create({
      nzTitle: modalTitle,
      nzContent: AssignUsersModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: true,
      nzClosable: true,
      nzBodyStyle: {padding: '10px'},
      nzComponentParams: {
        project: this.project,
        user: this.user,
        users: this.accountAssociatedUsers
      },
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: null
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(
      result => result ? (result.hasOwnProperty('assignedUsers') ? this.assignUsers(result)
      : (result.hasOwnProperty('inviteUser') ? this.inviteUsers(result) : null)) : null);
  }

  assignUsers(proj: any) {
    console.log('assignUsers', proj);
    if (!proj) {
      return;
    }

    const project = this.project;
    // this.project.assignedUsers = proj.assignedUsers;

    project.assignedUsers = proj.assignedUsers;

    let assignedProjectOwners = project.assignedProjectOwners.map((projectOwner) => projectOwner.publicKey);
    project.assignedProjectOwners = assignedProjectOwners;

    let phases = project.phases.map((phase) => phase.id);
    project.phases = phases;

    delete project['createdBy'];
    delete project['updatedBy'];
    console.log('project', project);
    this.loadingService.start();
    this.projectService.update(project)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.loadingService.complete();
        this.message.create('success', `Project was updated successfully!`);
        this.loadProject();
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error ${error.error.error}`);
    });
  }

  inviteUsers(email: any) {

    if (!email) {
      return;
    }

    const body = {
      receiver: email.inviteUser,
      subject: `${this.project.name} - Invitation to Collaborate`,
      projectName: this.project.name
    };

    this.loadingService.start();
    this.emailService.invite(body)
      .subscribe((resp) => {
        this.loadingService.complete();
        this.message.create('success', `Invite to ${email.inviteUser} sent successfully!`);
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error ${error.error.error}`);
      });

  }

  openUploadedDocumentsDialog(order) {
    const dialogRef = this.dialog.open(UploadedDocumentsDialogComponent, {
      data: {
        title: `Compliance Evidence - ${order?.order_no}`,
        links: order?.documents
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  importOrders(result) {
    console.log(result);
    if (!result) {
      return;
    }
  }

  onBack() {
    this.location.back();
  }

}
