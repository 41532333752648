<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let user"> <a [routerLink]="['/dashboard', { outlets: { dashboard: ['users', user?.publicKey] } }]">{{ user?.name }}</a> </td>
    </ng-container>

    <!-- Item Name Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
      <td mat-cell *matCellDef="let user"> {{ user?.role?.type | titlecase }} </td>
    </ng-container>

    <!-- Item Name Column -->
    <ng-container matColumnDef="subRole">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub Role </th>
      <td mat-cell *matCellDef="let user"> {{ user?.subRole?.type | split:'_'| join:' '| titlecase }} </td>
    </ng-container>

    <!-- Location -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let user"> {{ user?.address.address }} </td>
    </ng-container>

    <!-- Planned Completion Date Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
      <td mat-cell *matCellDef="let user"> {{ user?.description }} </td>
    </ng-container>

    <!-- Planned Delivery Date Column -->
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
      <td mat-cell *matCellDef="let user"> {{ user?.created_on * 1000 | date: 'yy-MM-dd, HH:mm' }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let order; columns: displayedColumns;">
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>


<!-- <nz-table #userTable [nzData]="users" [nzLoading]="showLoading">
  <thead>
    <tr>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;"><strong>Name</strong></th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;"><strong>Role</strong></th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;"><strong>Sub Role</strong></th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;"><strong>Address</strong></th>
      <th style="background: #4c6369; color: white; box-shadow: 5px 0 5px -2px #4c6369; z-index: 1;"><strong>Description</strong></th>
      <th style="background: #004b62; color: white;">Created on</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of userTable.data; let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><a [routerLink]="['/dashboard', { outlets: { dashboard: ['users', user?.publicKey] } }]">{{ user?.name }}</a></td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ user?.role?.type | titlecase }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ user?.subRole?.type | split:'_'| join:' '| titlecase }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ user?.address }}</td>
      <td style="color: #4c6369;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ user?.description }}</td>
      <td style="color: #004b62;">{{ user?.created_on * 1000 | date: 'yyyy-MM-dd, h:mm a' }}</td>
      <td>
        <a>Action 一 {{ data.name }}</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a>Delete</a>
      </td>
    </tr>
  </tbody>
</nz-table> -->
