import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '@plasma/components/dialog/help-dialog.component';
import { HELP_SECTIONS } from '@plasma/data/help.reference';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {

  }

  openDialog(section: string, isMobile: boolean = false) {
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      data: {
        title: HELP_SECTIONS[section].title,
        paragraphs: HELP_SECTIONS[section].paragraphs,
        imgLinks: HELP_SECTIONS[section].imgLinks,
        isMobile
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
