import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-uploaded-documents-dialog',
  templateUrl: './uploaded-documents.component.html',
  styleUrls: ['./uploaded-documents.component.scss']
})
export class UploadedDocumentsDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    extractFilename(link) {
      return decodeURI(link).split(/^.*[\\\/](.+)/)[1];
    }
}
