<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title style="color: #046888;"><strong>Provenance</strong></nz-page-header-title>
  <nz-page-header-extra *ngIf="provenances">
    <button nzTooltipTitle="Generate Export Certificate" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip (click)="generateExportCertificate()"><fa-icon icon="file-download"></fa-icon></button>
  </nz-page-header-extra>
</nz-page-header>
<ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
  <form novalidate [formGroup]="provenanceForm" (ngSubmit)="onSubmit(provenanceForm)">
    <div [hidden]="true" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Account</mat-label>
          <input matInput formControlName="account">
          <mat-error *ngIf="provenanceForm.get('account').invalid">The input is not a valid Account!</mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- <div [hidden]="true" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Project</mat-label>
          <input matInput formControlName="project">
          <mat-error *ngIf="provenanceForm.get('project').invalid">The input is not a valid Project!</mat-error>
        </mat-form-field>
      </div>
    </div> -->
    <div [hidden]="true" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Order</mat-label>
          <input matInput formControlName="order">
          <mat-error *ngIf="provenanceForm.get('order').invalid">The input is not a valid Order!</mat-error>
        </mat-form-field>
      </div>
    </div>
    <ng-container formArrayName="assets" *ngFor="let item of provenanceForm.get('assets')['controls']; let i = index;">
      <ng-container [formGroupName]="i">
        <div [hidden]="true" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
          <div fxLayoutAlign="center center" id="field-container">
            <mat-form-field appearance="fill" fxFlexFill>
              <mat-label>Asset ID</mat-label>
              <input matInput formControlName="id">
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
          <div fxLayoutAlign="center center" id="field-container">
            <mat-form-field appearance="fill" fxFlexFill>
              <mat-label>Item Name</mat-label>
              <input matInput formControlName="assetType">
            </mat-form-field>
          </div>

          <div fxLayoutAlign="center center" id="field-container">
            <mat-form-field appearance="fill" fxFlexFill>
              <mat-label>Attach Info</mat-label>
              <mat-select formControlName="ancestor" multiple>
                <mat-option value="PLEASE_SELECT">Please Select</mat-option>
                <mat-option *ngFor="let item of inventoryItems" [value]="item.id">{{item.asset_type}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div style="box-sizing: border-box; font-size: 75%;">
      <div class="mat-error" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center" *ngIf="provenanceForm.errors?.inventoryError && (provenanceForm.touched || provenanceForm.dirty)">
        Please attach provenance to one of the items
      </div>
    </div>
    

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
      <div fxLayoutAlign="center center" id="field-container">
        <button type="submit" mat-raised-button fxFlexFill [disabled]="provenanceForm.invalid" style="color: white; background-color: #2196f3;">Submit</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-container *ngFor="let key of getKeys(provenances); let i = index;">
  <div class="mt-2">
    <h5>{{key}}</h5>
    <div class="mat-elevation-z8">
      <table mat-table #provenanceTable [dataSource]="provenances[key]" matSort>
    
        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
          <td mat-cell *matCellDef="let row"> <div *ngIf="row?.inventory_id;else withoutInventoryID"><a [routerLink]="['/dashboard', { outlets: { dashboard: ['provenance-detail', row?.inventory_id] } }]">{{ row?.assetType }}</a></div> <ng-template #withoutInventoryID>{{ row?.assetType }}</ng-template> </td>
        </ng-container>
    
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
          <td mat-cell *matCellDef="let row"> {{row?.inventory?.company}} </td>
        </ng-container>
    
        <ng-container matColumnDef="companyAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center"> Company Address </th>
          <td mat-cell *matCellDef="let row" style="text-align: center"> <img [matTooltip]="row?.inventory?.companyAddress?.address + ' (click to copy)'" matTooltipPosition="above" (click)="copyToClipboard(row?.inventory?.companyAddress?.address)" src="assets/img/location-question.png" style="width: 1.25em"></td>
        </ng-container>
    
        <ng-container matColumnDef="originOfManufacturer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Origin of Manufacturer </th>
          <td mat-cell *matCellDef="let row"> <a [routerLink]="['/dashboard', { outlets: { dashboard: ['map'] } }]" [queryParams]="getLatLng(row?.inventory?.originOfManufacturer)">{{ row?.inventory?.originOfManufacturer?.address }}</a> </td>
        </ng-container>
  
        <ng-container matColumnDef="associatedCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Associated Code </th>
          <td mat-cell *matCellDef="let row"> {{ row?.inventory?.associatedCode }} </td>
        </ng-container>
  
        <ng-container matColumnDef="productionProcessDetails">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Process Details </th>
          <td mat-cell *matCellDef="let row"> {{ row?.inventory?.productionProcessDetails }} </td>
        </ng-container>
  
        <ng-container matColumnDef="materialsUsed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Materials Used </th>
          <td mat-cell *matCellDef="let row"> {{ row?.inventory?.materialsUsed }} </td>
        </ng-container>
  
        <ng-container matColumnDef="applicableLaws">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Applicable Laws </th>
          <td mat-cell *matCellDef="let row"> {{ row?.inventory?.applicableLaws }} </td>
        </ng-container>
  
        <ng-container matColumnDef="parentProducts">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Parent Products </th>
          <td mat-cell *matCellDef="let row"> {{ row?.inventory?.parentProducts?.length }} </td>
        </ng-container>
  
        <ng-container matColumnDef="isRawMaterial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Raw Material? </th>
          <td mat-cell *matCellDef="let row"> {{ row?.inventory?.isRawMaterial ? 'Yes' : 'No' }} </td>
        </ng-container>
  
        <ng-container matColumnDef="updatedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
          <td mat-cell *matCellDef="let row"> {{ row?.inventory?.createdOn | date: 'yyyy-MM-dd, h:mm a' }} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
</ng-container>
