import { Component, OnInit } from '@angular/core';

import { AuthService } from '@plasma/services/auth.plasma';
import { AccountService } from '@plasma/services/account.plasma';

import { IAgent } from '@plasma/models/agent';
import { ISupplier } from '@plasma/models/supplier';

import { NzMessageService } from 'ng-zorro-antd/message';

import { MatDialog } from '@angular/material/dialog';
import { AnalyticsDialogComponent } from '@plasma/components/dialog/analytics-dialog.component';


@Component({
  selector: 'app-suppliers-status',
  templateUrl: './suppliers-status.component.html',
  styleUrls: ['./suppliers-status.component.scss']
})
export class SuppliersStatusComponent implements OnInit {

  suppliers: any[];
  supplierList: any[];
  showLoading = false;
  displayChart = false;
  displayRightFirstTimeChart = false;
  displayConformanceChart = false;
  initOpts = {
    width: 450,
    height: 300
  };

  stackedHorizontalBarChart: any = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // Use axis to trigger tooltip
            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
        }
    },
    legend: {},
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'value'
    },
    yAxis: {
        type: 'category'
    },
    series: [],
    color: ['#91cc75', '#ee6666']
  };

  constructor(private authService: AuthService,
              private accountService: AccountService,
              private message: NzMessageService,
              public dialog: MatDialog) { }

  ngOnInit(): void {

    this.showLoading = true;
    this.authService.getUser()
      .subscribe((user: IAgent) => {
        this.accountService.getSuppliersStatus(user.account.id)
          .subscribe((suppliersStatus) => {
            this.suppliers = suppliersStatus;
            this.supplierList = Object.keys(suppliersStatus);
            this.loadSuppliersStatus();
            this.showLoading = false;
          }, (error) => {
            this.showLoading = false;
            console.error('Error', error);
            this.message.create('error', `Error: ${error.error}`);
          });
      });
  }

  loadSuppliersStatus() {
    console.log('this.suppliers', this.suppliers);
    if (Object.keys(this.suppliers).length === 0) {
      return;
    }
    const suppliers = Object.keys(this.suppliers);
    this.stackedHorizontalBarChart.legend.data = ['On Time', 'Delayed'];
    // this.stackedHorizontalBarChart.yAxis.data = ['Patera', 'United Welding Supplies', 'Wedge Galvinisors'];
    this.stackedHorizontalBarChart.yAxis.data = suppliers;

    const data = {};
    data['On Time'] = [];
    data['Delayed'] = [];
    suppliers.forEach((supplier) => {
      data['On Time'].push(this.suppliers[supplier]['ordersOnTime']);
      data['Delayed'].push(this.suppliers[supplier]['ordersDelayed']);
    });
    console.log('this.stackedHorizontalBarChart.yAxis.data', this.stackedHorizontalBarChart.yAxis.data);
    console.log('data', data);
    this.stackedHorizontalBarChart.series.push({
      name: 'On Time',
      type: 'bar',
      stack: 'total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: data['On Time']
    });

    this.stackedHorizontalBarChart.series.push({
      barWidth: 40,
      name: 'Delayed',
      type: 'bar',
      stack: 'total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: data['Delayed']
    });
    this.displayChart = true;
    this.displayRightFirstTimeChart = true;
    this.displayConformanceChart = true;
  }

  calculateOnTimeInFull(supplier) {
    return (supplier['ordersOnTime'] / supplier['totalOrders']) * 100;
  }

  calculateRightFirstTime(supplier) {
    return (1 - (supplier['ordersRejected'] / supplier['totalOrders'])) * 100;
  }

  calculateConformanceToSchedule(supplier) {
    return (supplier['ordersRejected'] / supplier['totalOrders']) * 100;
  }

  calculateDefectedPer(supplier) {
    return (supplier['ordersRejected'] * 1000000) / supplier['totalOrders'];
  }

  tooltipFormatter(params) {
    let tooltip = `<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${params.seriesName}</div>`;
    tooltip += `<div style="margin: 10px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;">`;
    tooltip += `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span><span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params.name}</span>`;
    tooltip += `<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${params.value}%</span>`;
    tooltip += `<div style="clear:both"></div></div><div style="clear:both"></div></div>`;
    return tooltip;
  }

  generateHorizontalBarChart(name, data) {
    return {
      tooltip: {
          trigger: 'axis',
          axisPointer: {            // Use axis to trigger tooltip
              type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
          }
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      xAxis: {
          type: 'value'
      },
      yAxis: {
          type: 'category'
      },
      series: this.convertIntoBarChartSeries,
      color: ['#91cc75', '#ee6666']
    };
  }

  generatePieChart(name, type, data) {
    return {
      tooltip: {
          trigger: 'item',
          formatter: this.tooltipFormatter
      },
      legend: {
          top: '5%',
          left: 'center'
      },
      series: [
          {
              name,
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                  show: false,
                  position: 'center'
              },
              labelLine: {
                  show: false
              },
              data: this.convertIntoChartData(type, data)
          }
      ],
      color: this.getColors(type)
    };
  }

  convertIntoBarChartSeries(values) {
    const series = [];
    values.forEach(value => {
      series.push({
        name: 'Delayed',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: value
      });
    });

    return series;
  }

  convertIntoChartData(type, value) {
    const data = [];
    let remainder = null;
    if (value < 100) {
      remainder = 100 - value;
    }
    if (type === 'right_first_time') {
      data.push({value, name: 'Right First Time'});
      if (remainder) {
        data.push({value: remainder, name: 'Not Right First Time'});
      }

    } else if (type === 'conformance_to_schedule') {
      data.push({value, name: 'Conformance to Schedule'});
      if (remainder) {
        data.push({value: remainder, name: 'Non Conformance to Schedule'});
      }
    }
    return data;
  }

  getColors(type) {
    if (type === 'right_first_time') {
      return ['#73c0de', '#808080'];
    } else {
      return ['#9a60b4', '#fc8452'];
    }
  }

  openDialog(supplierName) {
    const dialogRef = this.dialog.open(AnalyticsDialogComponent, {
      data: {
        title: supplierName,
        suppliers: this.suppliers,
        displayRightFirstTimeChart: this.displayRightFirstTimeChart,
        displayConformanceChart: this.displayConformanceChart,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
