import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { take, filter } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { AssetService } from '@plasma/services/asset.plasma';

import { StateOptions, StageOptions, ModeOfTransportOptions } from '@data/base.reference';

import { IAsset } from '@plasma/models/asset';
// import { ISupplier } from '@plasma/models/supplier';

import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam, UploadFile } from 'ng-zorro-antd/upload';

import { StateDialogComponent } from '@plasma/components/dialog/state-dialog.component';

import * as moment from 'moment';


@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit {

  id: string;
  asset: FormGroup;
  states: any[] = StateOptions;
  stages: any[] = StageOptions;
  modeOfTransport: any[] = ModeOfTransportOptions;
  defaultFileList: UploadFile[] = [
    {
      uid: '-1',
      name: 'xxx.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    },
    {
      uid: '-2',
      name: 'yyy.png',
      status: 'error'
    }
  ];

  preStateUploading = false;
  postStateUploading = false;
  stageUploading = false;

  preStateFiles: UploadFile[] = [];
  postStateFiles: UploadFile[] = [];
  stageFiles: UploadFile[] = [];

  constructor(
    private assetService: AssetService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected fb: FormBuilder,
    private message: NzMessageService,
    private location: Location,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.asset = this.fb.group({
      id: [null, [Validators.required]],
      asset_type: [{value: null, disabled: true}, [Validators.required]],
      name: [null],
      order: this.fb.group({
        id: [null, [Validators.required]],
        order_no: [{value: null, disabled: true}, [Validators.required]],
        timestamp: [{value: null, disabled: true}]
      }),
      owners: this.fb.array([this.loadOwners()]),
      locations: this.fb.array([this.loadLocations()]),
      description: [null],
      float_days: [null],
      uoq: [null],
      unit: [null],
      quantity: [{value: null, disabled: true}, [Validators.required]],
      price: [null, [Validators.required]],
      discount: [null],
      planned_dates: this.fb.group({
        planned_completion_date: [null],
        planned_delivery_date: [null],
        planned_installed_date: [null],
      }),
      actual_dates: this.fb.group({
        actual_completion_date: [null],
        actual_delivery_date: [null],
        actual_installed_date: [null],
      }),
      supplier: this.fb.group({
        agent_id: [null, [Validators.required]],
        name: [{value: null, disabled: true}, [Validators.required]],
        timestamp: [null]
      }),
      manufacturer: this.fb.group({
        agent_id: [null],
        name: [null],
        timestamp: [null]
      }),
      pre_state: this.fb.group({
        state: ['STATE_UNSPECIFIED'],
        images: this.fb.array([]),
        documents: this.fb.array([])
      }),
      post_state: this.fb.group({
        state: ['STATE_UNSPECIFIED'],
        images: this.fb.array([]),
        documents: this.fb.array([])
      }),
      stage: this.fb.group({
        stage: ['STAGE_UNSPECIFIED'],
        documents: this.fb.array([])
      }),
      mode_of_transport: [null],
      is_accepted: [null],
      is_rejected: [null],
      barcode: [null],
      created_on: [{value: null, disabled: true}],
      created_by: [{value: null, disabled: true}],
      updated_on: [{value: null, disabled: true}]
    });

    /*this.asset.get('is_accepted').valueChanges
      .subscribe(accepted => accepted ? this.asset.get('is_rejected').disable() : this.asset.get('is_rejected').enable());

    this.asset.get('is_rejected').valueChanges
      .subscribe(rejected => rejected ? this.asset.get('is_accepted').disable() : this.asset.get('is_accepted').enable());*/

    this.assetService.get(this.id)
      .subscribe((asset) => {
        if (asset) {
          console.log('asset', asset);
          if (asset['planned_dates']['planned_completion_date'] !== 0) {
            asset['planned_dates']['planned_completion_date'] = moment(asset['planned_dates']['planned_completion_date']).toDate();
          }

          if (asset['planned_dates']['planned_delivery_date'] !== 0) {
            asset['planned_dates']['planned_delivery_date'] = moment(asset['planned_dates']['planned_delivery_date']).toDate();
          }

          if (asset['planned_dates']['planned_installed_date'] !== 0) {
            asset['planned_dates']['planned_installed_date'] = moment(asset['planned_dates']['planned_installed_date']).toDate();
          } else {
            asset['planned_dates']['planned_installed_date'] = null;
          }

          if (asset['actual_dates']['actual_completion_date'] !== 0) {
            asset['actual_dates']['actual_completion_date'] = moment(asset['actual_dates']['actual_completion_date']).toDate();
          } else {
            asset['actual_dates']['actual_completion_date'] = null;
          }

          if (asset['actual_dates']['actual_delivery_date'] !== 0) {
            asset['actual_dates']['actual_delivery_date'] = moment(asset['actual_dates']['actual_delivery_date']).toDate();
          } else {
            asset['actual_dates']['actual_delivery_date'] = null;
          }

          if (asset['actual_dates']['actual_installed_date'] !== 0) {
            asset['actual_dates']['actual_installed_date'] = moment(asset['actual_dates']['actual_installed_date']).toDate();
          } else {
            asset['actual_dates']['actual_installed_date'] = null;
          }
          this.preStateImages.clear();
          for (const image of asset?.pre_state?.images) {
            this.preStateImages.push(this.loadImage());
          }

          this.postStateImages.clear();
          for (const image of asset?.post_state?.images) {
            this.postStateImages.push(this.loadImage());
          }

          this.preStateDocuments.clear();
          for (const document of asset?.pre_state?.documents) {
            this.preStateDocuments.push(this.fb.control(document));
          }

          this.postStateDocuments.clear();
          for (const document of asset?.post_state?.documents) {
            this.postStateDocuments.push(this.fb.control(document));
          }

          this.stageDocuments.clear();
          for (const document of asset?.stage?.documents) {
            this.stageDocuments.push(this.fb.control(document));
          }
          this.asset.patchValue(asset);
        }
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error}`);
      });
  }

  get owners() {
    return this.asset.get('owners') as FormArray;
  }

  get locations() {
    return this.asset.get('locations') as FormArray;
  }

  get preStateImages() {
    return (this.asset.get('pre_state') as FormGroup).controls.images as FormArray;
  }

  get postStateImages() {
    return (this.asset.get('post_state') as FormGroup).controls.images as FormArray;
  }

  get preStateDocuments() {
    return (this.asset.get('pre_state') as FormGroup).controls.documents as FormArray;
  }

  get postStateDocuments() {
    return (this.asset.get('post_state') as FormGroup).controls.documents as FormArray;
  }

  get stageDocuments() {
    return (this.asset.get('stage') as FormGroup).controls.documents as FormArray;
  }

  extractExtension(s) {
    return s.split(/.([^.]+)$/)[1];
  }

  extractFilename(s) {
    return s.split(/^.*[\\\/](.+)/)[1];
  }

  loadOwners() {
    return this.fb.group({
      id: [null, [Validators.required]],
      name: [{value: null, disabled: true}],
      timestamp: [null]
    });
  }

  loadLocations() {
    return this.fb.group({
      latitude: [null],
      longitude: [null],
      name: [null],
      timestamp: [null]
    });
  }

  loadImage() {
    return this.fb.group({
      image_url: [null],
      location: this.loadLocations()
    });
  }

  onSubmit({ valid, value }: { valid: boolean, value: IAsset }) {

    if (valid) {
      if (value['planned_dates']['planned_completion_date']) {
        value['planned_dates']['planned_completion_date'] = moment(value['planned_dates']['planned_completion_date']).valueOf();
      }

      if (value['planned_dates']['planned_delivery_date']) {
        value['planned_dates']['planned_delivery_date'] = moment(value['planned_dates']['planned_delivery_date']).valueOf();
      }

      if (value['planned_dates']['planned_installed_date']) {
        value['planned_dates']['planned_installed_date'] = moment(value['planned_dates']['planned_installed_date']).valueOf();
      }

      if (value['actual_dates']['actual_completion_date']) {
        value['actual_dates']['actual_completion_date'] = moment(value['actual_dates']['actual_completion_date']).valueOf();
      }

      if (value['actual_dates']['actual_delivery_date']) {
        value['actual_dates']['actual_delivery_date'] = moment(value['actual_dates']['actual_delivery_date']).valueOf();
      }

      if (value['actual_dates']['actual_installed_date']) {
        value['actual_dates']['actual_installed_date'] = moment(value['actual_dates']['actual_installed_date']).valueOf();
      }

      value['asset_type'] = this.asset.getRawValue()['asset_type'];
      value['owners'] = this.asset.getRawValue()['owners'];
      value['order'] = this.asset.getRawValue()['order'];
      value['supplier'] = this.asset.getRawValue()['supplier'];
      value['quantity'] = this.asset.getRawValue()['quantity'];
      value['updated_on'] = moment().valueOf();
      console.log('value', value);
      this.assetService.update(value)
        .subscribe((asset) => {
          console.log('asset', asset);
          this.message.create('success', `Asset was updated successfully!`);
          this.onBack();
        },
        (error) => {
          console.error('Error', error.error);
          this.message.create('error', `Error: ${error.error.error}`);
        });
    }
  }

  beforeUpload(file: UploadFile, list: UploadFile[]): UploadFile[] {
    list = list.concat(file);
    return list;
  }

  beforePreStateUpload = (file: UploadFile): boolean => {
    this.preStateFiles = this.beforeUpload(file, this.preStateFiles);
    return false;
  }

  beforePostStateUpload = (file: UploadFile): boolean => {
    this.postStateFiles = this.beforeUpload(file, this.postStateFiles);
    return false;
  }

  beforeStageUpload = (file: UploadFile): boolean => {
    this.stageFiles = this.beforeUpload(file, this.stageFiles);
    return false;
  }

  handleUpload(e: any, key): void {
    e.preventDefault();
    const formData = new FormData();
    let stateValue: string;

    if (key === 'pre_state') {
      this.preStateFiles.forEach((file: any) => {
        formData.append('documents', file);
      });

      stateValue = (this.asset.get('pre_state') as FormGroup).controls.state.value;
      this.preStateUploading = true;

    } else if (key === 'post_state') {

      this.postStateFiles.forEach((file: any) => {
        formData.append('documents', file);
      });

      stateValue = (this.asset.get('post_state') as FormGroup).controls.state.value;
      this.postStateUploading = true;

    } else if (key === 'stage') {

      this.stageFiles.forEach((file: any) => {
        formData.append('documents', file);
      });

      stateValue = (this.asset.get('stage') as FormGroup).controls.stage.value;
      this.stageUploading = true;

    }

    this.assetService.uploadDocuments(this.id, key, stateValue, formData)
      .subscribe((resp) => {
        console.log('resp', resp);
        if (key === 'pre_state') {
          this.preStateUploading = false;
          this.preStateFiles = [];
          for (const document of resp?.links) {
            this.preStateDocuments.push(this.fb.control(document));
          }
        } else if (key === 'post_state') {
          this.postStateUploading = false;
          this.postStateFiles = [];
          for (const document of resp?.links) {
            this.postStateDocuments.push(this.fb.control(document));
          }
        } else if (key === 'stage') {
          this.stageUploading = false;
          this.stageFiles = [];
          for (const document of resp?.links) {
            this.stageDocuments.push(this.fb.control(document));
          }
        }
        this.message.success(`Files uploaded successfully!`);
      }, (error) => {
        console.error('Error', error);
        this.message.error(`Error ${error.error.error}`);
      });

  }

  handleChange({ file, fileList }: UploadChangeParam): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.message.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.message.error(`${file.name} file upload failed.`);
    }
  }

  assetAccepted(e) {
    console.log('assetAccepted');
    e.value ? this.asset.get('is_rejected').disable() : this.asset.get('is_rejected').enable();
  }

  assetRejected(e) {
    console.log('assetRejected');
    e.value ? this.asset.get('is_accepted').disable() : this.asset.get('is_accepted').enable();
  }

  onDateChange(value) {
    console.log('value', value, 'parsed', new Date(value));
  }

  openPreStateDialog() {
    this.dialog.open(StateDialogComponent, {
      data: {
        animal: 'panda',
      },
    });
  }

  onBack() {
    this.location.back();
  }

}
