import { Component, forwardRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MatOptionSelectionChange } from '@angular/material/core';

import { Subject, Subscription } from 'rxjs';

import { GeocodingFeatureProperties, PlaceSuggestion } from '../../models/geoapify';
import { FeatureCollection } from 'geojson';


const AUTOCOMPLETE_CONTROL_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutoCompleteComponent),
    multi: true
};


@Component({
    selector: 'app-auto-complete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss'],
    providers: [AUTOCOMPLETE_CONTROL_ACCESSOR]
})
export class AutoCompleteComponent implements ControlValueAccessor {

    private onTouch: Function;
    private onModelChange: Function;

    private choosenOption: PlaceSuggestion;

    private userInputTimeout: number;
    private requestSub: Subscription;


    registerOnChange(fn: any): void {
        this.onModelChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    writeValue(value) {
        this.value = value || 0;
    }

    @Input() label = '';
    @Input() error = false;

    searchOptions: Subject<PlaceSuggestion[]> = new Subject<PlaceSuggestion[]>();
    inputFieldFormControl: any;
    value = 10;

    API_KEY: string = 'fbba6f597f3744e496c342007e8a0174';

    constructor(private http: HttpClient) {}

    onInputChange(value) {
        console.log('input value', value);
        if (this.userInputTimeout) {
            clearTimeout(this.userInputTimeout);
        }

        if (this.choosenOption && this.choosenOption.shortAddress === value) {
            this.searchOptions.next(null);
            return;
        }

        if (!value || value.length < 3) {
            // do not need suggestions until for less than 3 letters
            this.searchOptions.next(null);
            return;
        }

        this.userInputTimeout = setTimeout(() => {
            this.generateSuggestions(value);
        }, 300) as unknown as number;
    }

    private generateSuggestions(text: string) {
        const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${text}&limit=5&apiKey=${this.API_KEY}`;

        if (this.requestSub) {
            this.requestSub.unsubscribe();
        }

        this.requestSub = this.http.get(url)
            .subscribe((data: FeatureCollection) => {
                const placeSuggestions = data.features.map(feature => {
                    const properties: GeocodingFeatureProperties = (feature.properties as GeocodingFeatureProperties);

                    return {
                        shortAddress: this.generateShortAddress(properties),
                        fullAddress: this.generateFullAddress(properties),
                        data: properties
                    };
                });

                this.searchOptions.next(placeSuggestions.length ? placeSuggestions : null);
            }, error => console.error(error));


    }

    private generateShortAddress(properties: GeocodingFeatureProperties): string {
        let shortAddress = properties.name;

        if (!shortAddress && properties.street && properties.housenumber) {
          // name is not set for buildings
          shortAddress = `${properties.street} ${properties.housenumber}`;
        }

        shortAddress += (properties.postcode && properties.city) ? `, ${properties.postcode}-${properties.city}` : '';
        shortAddress += (!properties.postcode && properties.city && properties.city  !== properties.name) ? `, ${properties.city}` : '';
        shortAddress += (properties.country && properties.country !== properties.name) ? `, ${properties.country}` : '';

        return shortAddress;
    }

    private generateFullAddress(properties: GeocodingFeatureProperties): string {
        let fullAddress = properties.name;
        fullAddress += properties.street ? `, ${properties.street}` : '';
        fullAddress += properties.housenumber ? ` ${properties.housenumber}` : '';
        fullAddress += (properties.postcode && properties.city) ? `, ${properties.postcode}-${properties.city}` : '';
        fullAddress += (!properties.postcode && properties.city && properties.city  !== properties.name) ? `, ${properties.city}` : '';
        fullAddress += properties.state ? `, ${properties.state}` : '';
        fullAddress += (properties.country && properties.country !== properties.name) ? `, ${properties.country}` : '';
        return fullAddress;
    }

    optionSelectionChange(option: PlaceSuggestion, event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            console.log('option', option);
          this.choosenOption = option;
        //   this.locationChange.emit(option);
          this.onModelChange(option);
        }
        this.onTouch();
    }
}
