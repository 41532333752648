import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SupplierAComponent } from './pages/supplier-a/supplier-a.component';
import { SupplierBComponent } from './pages/supplier-b/supplier-b.component';
import { SupplierCComponent } from './pages/supplier-c/supplier-c.component';
import { InvitationSignUpComponent } from './pages/invitation-sign-up/invitation-sign-up.component';

import { routes as dashboardRoutes } from './pages/dashboard/dashboard.routes';
import { routes as serviceProviderRoutes } from './service-provider-dashboard/service-provider-dashboard.routes';

import { AuthGuardService } from '@plasma/services/auth-guard';
import { ServiceProviderDashboardComponent } from './service-provider-dashboard/service-provider-dashboard.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignUpComponent
  },
  {
    path: 'signup/:id',
    component: InvitationSignUpComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: dashboardRoutes,
    canActivate: [AuthGuardService]
  },
  {
    path: 'service-provider',
    component: ServiceProviderDashboardComponent,
    children: serviceProviderRoutes,
    canActivate: [AuthGuardService]
  },
  {
    path: 'supplier-a',
    component: SupplierAComponent
  },
  {
    path: 'supplier-b',
    component: SupplierBComponent
  },
  {
    path: 'supplier-c',
    component: SupplierCComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


  /*{
    path: 'dashboard',
    component: DashboardComponent,
    children: dashboardRoutes
  }*/
