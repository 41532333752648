<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title style="color: #046888;"><strong>Linked Orders</strong></nz-page-header-title>
</nz-page-header>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="contract_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract No </th>
      <td mat-cell *matCellDef="let order"> {{ order?.contract_no }} </td>
    </ng-container>

    <!-- Item Name Column -->
    <ng-container matColumnDef="account_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account No </th>
      <td mat-cell *matCellDef="let order"> {{ order?.account_no }} </td>
    </ng-container>

    <!-- Location -->
    <ng-container matColumnDef="project_cost_centre_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Cost Centre Code </th>
      <td mat-cell *matCellDef="let order"> {{ order?.project_cost_centre_code }} </td>
    </ng-container>

    <!-- Planned Completion Date Column -->
    <ng-container matColumnDef="order_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No </th>
      <td mat-cell *matCellDef="let order"> <a [routerLink]="['/dashboard', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a> </td>
    </ng-container>

    <!-- Planned Delivery Date Column -->
    <ng-container matColumnDef="phase">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phase </th>
      <td mat-cell *matCellDef="let order"> {{ order?.phase?.number }} </td>
    </ng-container>

    <!-- Planned Install Date Column -->
    <ng-container matColumnDef="assets">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No. of Items </th>
      <td mat-cell *matCellDef="let order"> {{ order?.assets?.length }} </td>
    </ng-container>

    <!-- Process Stage Column -->
    <ng-container matColumnDef="agreed_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Price </th>
      <td mat-cell *matCellDef="let order"> £{{ order?.agreed_price | number: '1.2-3' }} </td>
    </ng-container>

    <!-- Mode of Transport Column -->
    <ng-container matColumnDef="destination">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Destination </th>
      <td mat-cell *matCellDef="let order" style="text-align: center"> <img [matTooltip]="order?.destination + ' (click to copy)'" matTooltipPosition="above" (click)="copyToClipboard(order?.destination)" src="assets/img/location-question.png" style="width: 1.25em"> </td>
    </ng-container>

    <!-- Pre-State Column -->
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
      <td mat-cell *matCellDef="let order"> {{ order?.createdOn | date: 'yy-MM-dd, HH:mm' }} </td>
    </ng-container>

    <!-- Mode of Transport Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let order"> <div class="circle" [ngClass]="calculateStatus(order?.assets)"></div> </td>
    </ng-container>

    <!-- Mode of Transport Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let order">
        <ng-template [ngIf]="user?.canTrackOrders">
          <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['track', order?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let order; columns: displayedColumns;">
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<!-- <nz-table #orderTable [nzData]="orders" [nzLoading]="showLoading">
  <thead>
    <tr>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 10;"><strong>Contract No</strong></th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 9;"><strong>Account No</strong></th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 8;"><strong>Project Cost Centre Code</strong></th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 7;"><strong>Order No</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 6;"><strong>Phase</strong></th>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;"><strong>No. of Items</strong></th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;"><strong>Total Price</strong></th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;"><strong>Destination</strong></th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;"><strong>Created On</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 1;"><strong>Status</strong></th>
      <th style="background: #4c6369; color: white;"><strong>Action</strong></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of orderTable.data; let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.contract_no }}</td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.account_no }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.project_cost_centre_code }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><a [routerLink]="['/dashboard', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a></td>
      <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.phase?.number }}</td>
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.assets?.length }}</td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">£{{ order?.agreed_price | number: '1.2-3' }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.destination }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.createdOn | date: 'yyyy-MM-dd, h:mm a' }}</td>
      <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><div class="circle" [ngClass]="calculateStatus(order?.assets)"></div></td>
      <td>
        <ng-template [ngIf]="user?.canTrackOrders">
          <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['track', order?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.canAuthorisePayment">
          <button nzTooltipTitle="Pay" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['payment', order?.id] } }]"><fa-icon icon="money-bill"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.canCheckProvenance">
          <button nzTooltipTitle="Provenance" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['provenance', order?.id] } }]"><fa-icon icon="tree"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER'">
          <button nzTooltipTitle="Linked Orders" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['linked-orders', order?.id] } }]"><fa-icon icon="link"></fa-icon></button>
        </ng-template>
      </td>
    </tr>
  </tbody>
</nz-table> -->

