import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-analytics-dialog',
  templateUrl: './analytics-dialog.component.html',
  styleUrls: ['./analytics-dialog.component.scss']
})
export class AnalyticsDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    calculateOnTimeInFull(supplier) {
      return (supplier['ordersOnTime'] / supplier['totalOrders']) * 100;
    }

    calculateRightFirstTime(supplier) {
      return (1 - (supplier['ordersRejected'] / supplier['totalOrders'])) * 100;
    }

    calculateConformanceToSchedule(supplier) {
      return (supplier['ordersRejected'] / supplier['totalOrders']) * 100;
    }

    calculateDefectedPer(supplier) {
      return (supplier['ordersRejected'] * 1000000) / supplier['totalOrders'];
    }

    tooltipFormatter(params) {
      let tooltip = `<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${params.seriesName}</div>`;
      tooltip += `<div style="margin: 10px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;">`;
      tooltip += `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span><span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${params.name}</span>`;
      tooltip += `<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${params.value}%</span>`;
      tooltip += `<div style="clear:both"></div></div><div style="clear:both"></div></div>`;
      return tooltip;
    }

    generatePieChart(name, type, data) {
      return {
        tooltip: {
            trigger: 'item',
            formatter: this.tooltipFormatter
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name,
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                labelLine: {
                    show: false
                },
                data: this.convertIntoChartData(type, data)
            }
        ],
        color: this.getColors(type)
      };
    }

    convertIntoChartData(type, value) {
      const data = [];
      let remainder = null;
      if (value < 100) {
        remainder = 100 - value;
      }
      if (type === 'right_first_time') {
        data.push({value, name: 'Right First Time'});
        if (remainder) {
          data.push({value: remainder, name: 'Not Right First Time'});
        }

      } else if (type === 'conformance_to_schedule') {
        data.push({value, name: 'Conformance to Schedule'});
        if (remainder) {
          data.push({value: remainder, name: 'Non Conformance to Schedule'});
        }
      }
      return data;
    }

    getColors(type) {
      if (type === 'right_first_time') {
        return ['#73c0de', '#808080'];
      } else {
        return ['#9a60b4', '#fc8452'];
      }
    }
}
