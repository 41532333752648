<div fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div>
            <h3>Help</h3>
        </div>
        <ul>
            <li>
                <a (click)="openDialog('registration')">
                    <div class="icon-container" id="icon-blue"><fa-icon icon="user-plus" size="2x"></fa-icon></div>
                    <!-- <img src="//www.gstatic.com/images/branding/product/1x/chrome_64dp.png" alt=""> -->
                    <h3>Registration</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('project')">
                    <div class="icon-container" id="icon-red"><fa-icon icon="pen" size="2x"></fa-icon></div>
                    <h3>Create Project</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('order')">
                    <div class="icon-container" id="icon-yellow"><fa-icon icon="pen" size="2x"></fa-icon></div>
                    <h3>Create Order</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('viewOrder')">
                    <div class="icon-container" id="icon-green"><fa-icon icon="eye" size="2x"></fa-icon></div>
                    <h3>View Orders</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('trackingIntelligence')">
                    <div class="icon-container" id="icon-red"><fa-icon icon="truck-moving" size="2x"></fa-icon></div>
                    <h3>Tracking Intelligence</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('linkBarcode')">
                    <div class="icon-container" id="icon-blue"><fa-icon icon="qrcode" size="2x"></fa-icon></div>
                    <h3>Link Barcode/QR Code</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('paymentAuthorization')">
                    <div class="icon-container" id="icon-green"><fa-icon icon="money-bill" size="2x"></fa-icon></div>
                    <h3>Payment Authorization</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('provenance')">
                    <div class="icon-container" id="icon-yellow"><fa-icon icon="tree" size="2x"></fa-icon></div>
                    <h3>Provenance/Traceability</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('uploadDocuments')">
                    <div class="icon-container" id="icon-yellow"><fa-icon icon="file-upload" size="2x"></fa-icon></div>
                    <h3>Upload Documents</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('linkedOrders')">
                    <div class="icon-container" id="icon-green"><fa-icon icon="link" size="2x"></fa-icon></div>
                    <h3>Linked Orders</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('projectStatus')">
                    <div class="icon-container" id="icon-blue"><fa-icon icon="info-circle" size="2x"></fa-icon></div>
                    <h3>Project Status</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('suppliersStatus')">
                    <div class="icon-container" id="icon-red"><fa-icon icon="info-circle" size="2x"></fa-icon></div>
                    <h3>Suppliers Status</h3>
                </a>
            </li>
            <li>
                <a (click)="openDialog('inventory')">
                    <div class="icon-container" id="icon-red"><fa-icon icon="warehouse" size="2x"></fa-icon></div>
                    <h3>Inventory</h3>
                </a>
            </li>
            <!-- <li>
                <a (click)="openDialog('suppliersNetwork')">
                    <div class="icon-container" id="icon-blue"><fa-icon icon="project-diagram" size="2x"></fa-icon></div>
                    <h3>Suppliers Network</h3>
                </a>
            </li> -->
        </ul>
    </div>
    
    <div fxLayout="column" fxLayoutAlign="center center">
        <div>
            <h3 style="margin-top: 10px;">Mobile App</h3>
        </div>
        <ul>
            <li style="width: 8.33vw;">
                <a (click)="openDialog('installation', true)">
                    <div class="icon-container" id="icon-red" style="margin: 1.75rem 0 1.25rem 0.95rem;"><fa-icon icon="file-download" size="2x"></fa-icon></div>
                    <h3>Installation</h3>
                </a>
            </li>
            <li style="width: 8.33vw;">
                <a (click)="openDialog('login', true)">
                    <div class="icon-container" id="icon-blue" style="margin: 1.75rem 0 1.25rem 0.95rem;"><fa-icon icon="sign-in-alt" size="2x"></fa-icon></div>
                    <h3>Log In</h3>
                </a>
            </li>
            <li style="width: 8.33vw;">
                <a (click)="openDialog('scanBarcode', true)">
                    <div class="icon-container" id="icon-yellow" style="margin: 1.75rem 0 1.25rem 0.95rem;"><fa-icon icon="barcode" size="2x"></fa-icon></div>
                    <h3>Scan Barcode</h3>
                </a>
            </li>
            <li style="width: 8.33vw;">
                <a (click)="openDialog('uploadPictures', true)">
                    <div class="icon-container" id="icon-green" style="margin: 1.75rem 0 1.25rem 0.95rem;"><fa-icon icon="file-upload" size="2x"></fa-icon></div>
                    <h3>Upload Pictures</h3>
                </a>
            </li>
        </ul>
    </div>
</div>







<!-- <p><b>Registration</b></p>
<p>Fill in the required fields (fields with *)</p>
<p>If your company is not listed in Account dropdown, then select Create New</p>
<p>After you register you will see a form to create the company/account.</p>
<p>Without the account/company the user will not be able to do anything</p>
<img src="assets/img/registration.png" width="800">
<img src="assets/img/create account.png" width="800">

<p><b>Create a Project</b></p>
<p>'Fill in the required fields (fields with *)'</p>
<p>'Specify phases for your project'</p>
<p>'Assign users which can view and create orders in the project.'</p>
<p>'Click on View Project in order to access the project.'</p>
<img src="assets/img/create project.png" width="800">
<img src="assets/img/view project.png" width="800">

<p><b>Create an Order/Requisition</b></p>

<p>You can create an order/requisition by going to a project, and clicking on "Create Order" button</p>
<p>Fill in the required fields (fields with *)</p>
<p>If you want to add items to the order click on Add Item button on right side.</p>
<p>If you want to remove an item click on - sign against the item</p>
<p>After filling the fields click on Create Order button at the bottom.</p>
<p>You will receive notification, "Order was created successfully"</p>
<img src="assets/img/create order.png" width="800">


<p><b>View Current Orders to work on</b></p>
<p>To view orders go to a project, click on "View Project"</p>
<p>You will see list of your current orders</p>
<img src="assets/img/orders.png" width="800">


<p><b>Provide tracking intelligence and updates to all supply chain participants</b></p>
<p>You can view tracking information by going to the list of orders view, and clicking on tracking button</p>
<img src="assets/img/track-btn.png" width="800">
<img src="assets/img/order.png" width="800">


<p><b>Link Bar Code/QR Code to order/requisition on the system</b></p>
<p>Open an order by clicking on track button against an order</p>
<p>At the order view, at the bottom you will see a list of items related to the order.</p>
<p>Click on any id under the "Id" column to see the details of the item</p>
<p>In the item view, you can add barcode against the "Barcode" field.</p>
<img src="assets/img/item.png" width="800">
<img src="assets/img/barcode.png" width="800">

<p><b>Trigger Event (Payment Authorization)</b></p>
<p>Follow the steps mentioned under the heading "View Current Orders to Work On", at the right side under Action column click on bank note icon, payment details are displayed and you can click on "Authorize Payment" to send payment.</p>
<img src="assets/img/payment.png" width="800">

<p><b>Provenance/Traceability of Component</b></p>
<p>You can view tracking information by going to the list of orders view, and clicking on provenance button</p>
<p>Provenance will only be available for orders containing doors</p>
<p>If an order does not contain doors, provenance table will be empty</p>
<img src="assets/img/provenance-btn.png" width="800">
<img src="assets/img/provenance.png" width="800">

<p><b>Upload documents alongside a status update</b></p>
<p>Documents can be uploaded for an order by going to the Order view.</p>
<img src="assets/img/order-document-upload.png" width="800">
<p>Documents for an item can be uploaded by going to the Item view.</p>
<img src="assets/img/item-document-upload.png" width="800">

<p><b>Check Linked Orders</b></p>
<p>You can view orders that are linked together by clicking on linked orders option in the list of orders screen.</p>
<p>Click on linked orders button.</p>
<img src="assets/img/linked orders option.png" width="800">
<img src="assets/img/linked orders.png" width="800">

<p><b>Check Project Status</b></p>
<p>Follow the steps from "View Current Orders to work on"</p>
<p>On the top you should see "Project Status" button, click on it.</p>
<img src="assets/img/project status option.png" width="800">
<p>This will display current project status.</p>
<img src="assets/img/project status.png" width="800">

<p><b>Check Suppliers Status</b></p>
<p>You can check suppliers status by clicking on the left hand side main menu.</p>
<img src="assets/img/suppliers status.png" width="800">

<p><b>Access Suppliers Network</b></p>
<p>You can access suppliers network by clicking on the left hand side main menu.</p>
<img src="assets/img/suppliers network.png" width="800">


<h4>Mobile App</h4>

<p><b>Installation</b></p>
<p>Open the installation file (plasma.apk) on your smartphone</p>
<p>After openning the file, it will prompt you if you want to install the app</p>
<p>Click on "Install"</p>
<p>After installation, search for an app known as "Plasma"</p>
<img src="assets/img/mobile-app.jpg" width="200">


<p><b>Log in to the Mobile App</b></p>
<p>Open the app</p>
<p>Fill in the details on the login screen</p>
<img src="assets/img/mobile-login.jpg" width="200">


<p><b>Scan Bar Code/QR Code</b></p>
<p>Perform the login steps</p>
<p>When you will login, app will take you to the scan screen</p>
<p>Click on the bottom right button</p>
<p>Click on the QR code icon, to scan barcode</p>
<p>If the barcode is registered, the app will display the details of the item</p>
<img src="assets/img/mobile-scan-barcode.jpg" width="200">
<img src="assets/img/mobile-item.jpg" width="200">


<p><b>Upload picture alongside a status update to the blockchain</b></p>
<p>Perform the steps "Scan Bar Code/QR Code"</p>
<p>Only a contractor can take pictures at the moment</p>
<p>When you will change the pre-state or post-state of the Item to either (Damaged or Undamaged), it will open up your camera so you can take picture</p>
<p>The picture taken will be displayed at the bottom of the screen</p>
<p>When you will click "Update" the picture will be uploaded and item details will be updated</p>
<img src="assets/img/mobile-pre-state-update.jpg" width="200">
<img src="assets/img/mobile-take-picture.jpg" width="200">
<img src="assets/img/mobile-asset-updated.jpg" width="200"> -->



