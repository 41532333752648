import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { CreateAccountModalComponent } from '@plasma/components/modal/create-account-modal.component';
import { LoadingService } from '@plasma/components/loading/loading.service';

import { IOrder } from '../../models/order';
import { IAgent } from '../../models/agent';
import { IAccount } from '@plasma/models/account';
import { ORDERS } from '../../data/order.reference';

import { AuthService } from '@plasma/services/auth.plasma';
import { AccountService } from '@plasma/services/account.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';

import { take } from 'rxjs/operators';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isCollapsed = false;
  validateForm: FormGroup;
  public orders: IOrder[];
  user: any;

  constructor(private fb: FormBuilder,
              public auth: AuthService,
              public router: Router,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private accountService: AccountService,
              private agentService: AgentsService,
              private loadingService: LoadingService,
              private message: NzMessageService) { }

  ngOnInit(): void {

    this.auth.getUser()
      .pipe(take(1))
      .subscribe((user: any) => {
        console.log('user', user);
        this.user = user;
        console.log('user account id length', user.account.id.length);
        if (this.user && this.user.account && (!this.user.account.id && 0 === this.user.account.id.length)) {
          // user not linked to an account
          this.createModal();
        }
      }, (error) => {
        console.error('Error', error);
      });

    /*this.user = this.auth.user;
    console.log('this.user', this.user);
    if (this.user && this.user.account && (!this.user.account.id && 0 === this.user.account.id.length)) {
      // user not linked to an account, open modal to create account
      this.createModal();
    }*/

    this.validateForm = this.fb.group({
      orderNo: [null, [Validators.required]],
      supplier: ['Supplier A', [Validators.required]],
      quantity: [null, [Validators.required]],
      unitPrice: [null, [Validators.required]],
      agreedPrice: [null, [Validators.required]],
      deliveryLocation: [null],
      completionDate: [null],
      description: [null],
      paymentTerms: [null],
      discounts: [null],
      sku: [null],
      billingAddress: [null, [Validators.required]]
    });
  }

  submitForm({value, valid}: {value: IOrder, valid: boolean}): void {
    // for (const i in this.validateForm.controls) {
    //   this.validateForm.controls[i].markAsDirty();
    //   this.validateForm.controls[i].updateValueAndValidity();
    // }
    if (!valid) {
      return;
    }
    // this.orders.push(value);
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }

  logout() {
    this.auth.clearAuthToken();
    this.router.navigate(['/login']);
  }

  createModal(): void {
    const modal = this.modal.create({
      nzTitle: 'Create Account',
      nzContent: CreateAccountModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        name: this.user?.name,
        address: this.user?.address?.address,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzWidth: 'max-content',
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: [
        {
          label: 'Create Account',
          onClick: componentInstance => {
            componentInstance.createAccount();
          }
        }
      ]
    });
    const instance = modal.getContentComponent();
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => this.createAccount(result));
    // delay until modal instance created
    /*setTimeout(() => {
      instance.subtitle = 'sub title is changed';
    }, 2000);*/
  }

  createAccount(account: IAccount) {
    this.loadingService.start();
    this.accountService.create(account)
      .subscribe((resp) => {
        this.agentService.get(this.user.publicKey)
          .subscribe((updatedAgent) => {
            console.log('updatedAgent---', updatedAgent);
            this.user = updatedAgent;
            this.auth.setAgent(updatedAgent);
            this.loadingService.complete();
            this.message.create('success', `Account was created successfully!`);
          }, (error) => {
            console.error(`Error ${error.error.error}`);
        });
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error ${error.error.error}`);
    });
  }

}
