import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatTable } from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';

import { OrderService } from '@plasma/services/order.plasma';
import { ProvenanceService } from '@plasma/services/provenance.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { AssetService } from '@plasma/services/asset.plasma';
import { GeocodingService } from '@plasma/services/geocoding.plasma';
import { InventoryService } from '@plasma/services/inventory.plasma';

import { IAsset } from '@plasma/models/asset';
import { IAgent } from '@plasma/models/agent';
import { IProvenance } from '@plasma/models/provenance';

import { PROVENANCE } from '@plasma/data/provenance.reference';

import { NzMessageService } from 'ng-zorro-antd/message';

// import { MatPaginator } from '@angular/material/paginator';
// import { MatSort } from '@angular/material/sort';
// import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';

import { LoadingService } from '@plasma/components/loading/loading.service';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-provenance',
  templateUrl: './provenance.component.html',
  styleUrls: ['./provenance.component.scss']
})
export class ProvenanceComponent implements OnInit, OnDestroy, AfterViewInit {

  private destroy$ = new Subject<void>();

  user: IAgent;
  orderId: string;
  order: any;
  orders: any;
  provenances: any;
  provenanceForm: FormGroup;
  assets: IAsset[] = [];
  private parentProductsValidators = null;
  inventoryItems: any[];

  displayedColumns: string[] = ['productName', 'company', 'companyAddress', 'originOfManufacturer', 'associatedCode', 'productionProcessDetails', 'materialsUsed', 'applicableLaws', 'parentProducts', 'isRawMaterial', 'updatedOn'];
  dataSource = null;

  @ViewChild(MatTable) provenanceTable: MatTable<any>;

  constructor(private fb: FormBuilder,
              private orderService: OrderService,
              private provenanceService: ProvenanceService,
              private assetService: AssetService,
              private geocodingService: GeocodingService,
              private inventoryService: InventoryService,
              private authService: AuthService,
              private message: NzMessageService,
              private loadingService: LoadingService,
              private clipboard: Clipboard,
              protected activatedRoute: ActivatedRoute,
              private renderer: Renderer2,
              private location: Location) {}

  ngOnInit(): void {

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.orderId = id;
    if (!id) {
      return;
    }

    this.loadProvenanceForm();
    this.onChanges();
    this.loadingService.start();
    this.authService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IAgent) => {
        this.user = user;
        this.loadProvenances();
        this.loadInventory();
        this.loadOrder();
        this.loadOrders();
        this.provenanceForm.get('account').patchValue(user.account.id);
        this.loadingService.complete();
    }, error => { console.error(error); this.loadingService.complete(); });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {}

  loadOrder() {
    this.orderService.get(this.orderId)
      .subscribe((order) => {
        console.log('order', order);
        this.order = order;
        this.createAssetFormArray(order.assets);
        this.provenanceForm.get('order').patchValue(this.orderId);
        /* this.provenanceForm.get('project').patchValue(order?.project); */
      }, (error) => console.error(error));
  }

  loadOrders() {
    if (!this.user) {
      return;
    }

    this.orderService.getByAccount(this.user?.account?.id)
      .subscribe((orders) => {
        console.log('orders', orders);
        this.orders = orders;
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });
  }

  loadProvenanceForm() {
    this.provenanceForm = this.fb.group({
      account: [null, [Validators.required]],
      order: [null, [Validators.required]],
      assets: this.fb.array([])
    }, { validators: this.inventoryValidator });
  }

  inventoryValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const assets = control.get('assets');
    const isValid = assets.value.some(asset => asset.ancestor !== null && asset.ancestor.every(ances => ances !== 'PLEASE_SELECT') );

    return  isValid ? null : { inventoryError: true };
  };

  createAssetFormArray(assets: any[]) {
    console.log('assets', assets);
    assets.forEach((asset) => {
      (this.provenanceForm.get('assets') as FormArray).push(this.fb.group({
        assetType: [asset.asset_type, [Validators.required]],
        id: [asset.id, [Validators.required]],
        ancestor: [['PLEASE_SELECT'], [Validators.required]]
      }));
    });
  }

  loadInventory() {
    console.log('this.user?.account?.id', this.user?.account?.id);
    this.assetService.getByAccount(this.user?.account?.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((assets) => {
        this.inventoryItems = assets.filter((asset) => asset.order.id !== this.orderId);
        console.log('assets', assets);
      }, error => console.error(error));

  }

  onChanges() {
    /* this.provenanceForm.get('isRawMaterial').valueChanges
      .subscribe((value) => {
        if (value) {
          this.provenanceForm.get('parentProducts').setValidators(this.parentProductsValidators);
        } else {
          this.provenanceForm.get('parentProducts').setValidators(this.parentProductsValidators ? this.parentProductsValidators.concat(Validators.required) : [Validators.required]);
        }
      }); */
  }

  onSubmit({value, valid}: {value: any, valid: boolean}): void {
    if (!valid) {
      return;
    }

    value['createdOn'] = moment().valueOf();
    if (value['manufactureDate']) {
      value['manufactureDate'] = moment(value['manufactureDate']).valueOf();
    }

    value['assets'] = value['assets'].filter(asset => asset['ancestor'].every((ancestor => ancestor  !== 'PLEASE_SELECT')));


    value['supplier'] = this.user?.publicKey;

    console.log('value', value);
    this.loadingService.start();
    this.provenanceService.create(value)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.loadingService.complete();
        this.loadProvenances();
        this.message.create('success', `Provenance information was successfully added!`);
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });

  }

  loadProvenances() {
    this.provenanceService.getByOrder(this.orderId)
      .subscribe(
        provenances => {
          console.log('provenances', provenances);
          const keys = Object.keys(provenances);
          for (let i = 0; i < keys.length; i++) {
            provenances[keys[i]] = [...new Map(provenances[keys[i]].map((provenance) => [provenance['id'], provenance])).values()];
          }
          this.provenances = provenances;
          console.log('unique provenances', this.provenances);
        },
        error => console.error(error));
  }

  generateExportCertificate() {
    html2canvas(this.provenanceTable['_elementRef'].nativeElement)
      .then((canvas) => {
        const htmlWidth = canvas.width;
        const htmlHeight = canvas.height;
        const topLeftMargin = 35;
        const pdfWidth = htmlWidth + (topLeftMargin * 2);
        const pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2);
        const canvasWidth = htmlWidth;
        const canvasHeight = htmlHeight;
        const contentDataURL = canvas.toDataURL('image/png');
        let pdf = new jspdf('p', 'pt',  [pdfWidth, pdfHeight]); //Generates PDF in landscape mode
        // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
        pdf.text('TrackChain Export Certificate', 20, 20, { align: 'center' });
        pdf.addImage(contentDataURL, 'PNG', topLeftMargin, topLeftMargin, canvasWidth, canvasHeight);
        pdf.output('dataurlnewwindow', { filename: `${this.orderId}.pdf`});
      });
  }

  getLatLng(address) {
    if (!address) {
      return {lat: 45, lng: -50};
    }
    return {lat: address.lat, lng: address.lng};
  }

  copyToClipboard(text: string) {
    if (!text) {
      return;
    }
    this.clipboard.copy(text);
  }

  getKeys(provenances) {
    if (!provenances) {
      return null;
    }
    return Object.keys(provenances);
  }

  onBack() {
    this.location.back();
  }

}