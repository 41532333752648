<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title style="color: #046888;"><strong>Track</strong></nz-page-header-title>
</nz-page-header>
<ng-container *ngIf="assets">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="assets" matSort>
  
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let asset"> <a [routerLink]="['/dashboard', {outlets: { dashboard: ['items', asset?.id] }}]">{{ asset?.id.substring(0, 4) }}</a> </td>
      </ng-container>
  
      <!-- Item Name Column -->
      <ng-container matColumnDef="assetType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Item Name </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.asset_type === 'reco_walls' ? 'Reco Walls' : asset?.asset_type | titlecase }} </td>
      </ng-container>
  
      <!-- Location -->
      <ng-container matColumnDef="destination">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Destination </th>
        <td mat-cell *matCellDef="let asset" style="text-align: center"> <img [matTooltip]="asset?.locations[0]?.name + ' (click to copy)'" matTooltipPosition="above" (click)="copyToClipboard(asset?.locations[0]?.name)" src="assets/img/location-question.png" style="width: 1.25em"> </td>
      </ng-container>
  
      <!-- Planned Completion Date Column -->
      <ng-container matColumnDef="plannedCompletionDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Planned Completion <br/> Date </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.planned_dates?.planned_completion_date !== 0 ? (asset?.planned_dates?.planned_completion_date | date: 'yy-MM-dd, HH:mm') : '' }} </td>
      </ng-container>

      <!-- Planned Delivery Date Column -->
      <ng-container matColumnDef="plannedDeliveryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Planned Delivery <br/> Date </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.planned_dates?.planned_delivery_date !== 0 ? (asset?.planned_dates?.planned_delivery_date | date: 'yy-MM-dd, HH:mm') : '' }} </td>
      </ng-container>

      <!-- Planned Install Date Column -->
      <ng-container matColumnDef="plannedInstalledDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Planned Install <br/> Date </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.planned_dates?.planned_installed_date !== 0 ? (asset?.planned_dates?.planned_installed_date | date: 'yy-MM-dd, HH:mm') : '' }} </td>
      </ng-container>

      <!-- Process Stage Column -->
      <ng-container matColumnDef="stage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Process Stage Now </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.stage?.stage | stageSplit | titlecase }} </td>
      </ng-container>

      <!-- Mode of Transport Column -->
      <ng-container matColumnDef="modeOfTransport">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mode of Transport </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.mode_of_transport !== 'null' ? (asset?.mode_of_transport | titlecase) : '' | titlecase }} </td>
      </ng-container>

      <!-- Pre-State Column -->
      <ng-container matColumnDef="preState">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pre-State </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.pre_state.state | split: 'STATE_' | join: ' ' | titlecase }} </td>
      </ng-container>

      <!-- Mode of Transport Column -->
      <ng-container matColumnDef="postState">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Post-State </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.post_state.state | split: 'STATE_' | join: ' ' | titlecase }} </td>
      </ng-container>

      <!-- Mode of Transport Column -->
      <ng-container matColumnDef="actualCompletionDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Completion <br/> Date </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.actual_dates?.actual_completion_date !== 0 ? (asset?.actual_dates?.actual_completion_date | date: 'yy-MM-dd, HH:mm') : '' }} </td>
      </ng-container>

      <!-- Actual Delivery Date Column -->
      <ng-container matColumnDef="actualDeliveryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Delivery <br/> Date </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.actual_dates?.actual_delivery_date !== 0 ? (asset?.actual_dates?.actual_delivery_date | date: 'yy-MM-dd, HH:mm') : '' }} </td>
      </ng-container>

      <!-- Mode of Transport Column -->
      <ng-container matColumnDef="actualInstalledDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Install <br/> Date </th>
        <td mat-cell *matCellDef="let asset"> {{ asset?.actual_dates?.actual_installed_date !== 0 ? (asset?.actual_dates?.actual_installed_date | date: 'yy-MM-dd, HH:mm') : '' }} </td>
      </ng-container>

      <!-- Date Item Critical Column -->
      <ng-container matColumnDef="dateItemCritical">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Item Critical </th>
        <td mat-cell *matCellDef="let asset"> {{calculateCriticalDate(asset?.planned_dates?.planned_delivery_date, asset?.float_days) | date: 'yy-MM-dd'}} </td>
      </ng-container>

      <!-- Date Item Critical Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let asset"> <div class="circle" [ngClass]="calculateStatus(asset?.planned_dates?.planned_delivery_date, asset?.actual_dates?.actual_delivery_date, asset?.float_days)"></div> </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let asset; columns: displayedColumns;">
      </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
</ng-container>
<ng-container *ngIf="order">
  <!-- <nz-table #assetTable [nzData]="order?.assets" nzTableLayout="fixed" nzSize="small" class="mt-2">
    <thead>
      <tr>
        <th nzEllipsis style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 14;">Id</th>
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 13;">Item Name</th>
        <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 12;">Destination</th>
        <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 11;">Planned Completion Date</th>
        <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 10;">Planned Delivery Date</th>
        <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 9;">Planned Install Date</th>
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 8;">Process Stage Now</th>
        <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 7;">Mode of Transport</th>
        <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 6;">Pre-State</th>
        <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 5;">Post-State</th>
        <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 4;">Actual Completion Date</th>
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 3;">Actual Delivery Date</th>
        <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 2;">Actual Install Date</th>
        <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 1;">Date Item Critical</th>
        <th style="background: #004b62; color: white;">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let asset of assetTable.data; let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
        <td nzEllipsis style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><a [routerLink]="['/dashboard', {outlets: { dashboard: ['items', asset?.id] }}]">{{ asset?.id }}</a></td>
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.asset_type === 'reco_walls' ? 'Reco Walls' : asset?.asset_type | titlecase }}</td>
        <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.locations[0]?.name }}</td>
        <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.planned_dates?.planned_completion_date !== 0 ? (asset?.planned_dates?.planned_completion_date | date: 'yyyy-MM-dd, h:mm a') : '' }}</td>
        <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.planned_dates?.planned_delivery_date !== 0 ? (asset?.planned_dates?.planned_delivery_date | date: 'yyyy-MM-dd, h:mm a') : '' }}</td>
        <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.planned_dates?.planned_installed_date !== 0 ? (asset?.planned_dates?.planned_installed_date | date: 'yyyy-MM-dd, h:mm a') : '' }}</td>
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.stage?.stage | stageSplit | titlecase }}</td>
        <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.mode_of_transport !== 'null' ? (asset?.mode_of_transport | titlecase) : '' | titlecase }}</td>
        <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.pre_state.state | split: 'STATE_' | join: ' ' | titlecase }}</td>
        <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.post_state.state | split: 'STATE_' | join: ' ' | titlecase }}</td>
        <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.actual_dates?.actual_completion_date !== 0 ? (asset?.actual_dates?.actual_completion_date | date: 'yyyy-MM-dd, h:mm a') : '' }}</td>
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.actual_dates?.actual_delivery_date !== 0 ? (asset?.actual_dates?.actual_delivery_date | date: 'yyyy-MM-dd, h:mm a') : '' }}</td>
        <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ asset?.actual_dates?.actual_installed_date !== 0 ? (asset?.actual_dates?.actual_installed_date | date: 'yyyy-MM-dd, h:mm a') : '' }}</td>
        <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{calculateCriticalDate(asset?.planned_dates?.planned_delivery_date, asset?.float_days) | date: 'yyyy-MM-dd'}}</td>
        <td style="color: #004b62;"><div class="circle" [ngClass]="calculateStatus(asset?.planned_dates?.planned_delivery_date, asset?.actual_dates?.actual_delivery_date, asset?.float_days)"></div></td>
      </tr>
    </tbody>
  </nz-table> -->
  <nz-divider style="color: #046888;" [nzText]="definitionsHeading" nzOrientation="left">
    <ng-template #definitionsHeading>
      <strong>Definitions</strong>
    </ng-template>
  </nz-divider>
  <ol>
    <li><span style="color: #046888;">Destination</span><span style="color: #00a2a4"> - Applies to all 'Levels'. Final location of tracked component on site.</span></li>
    <li><span style="color: #046888;">Process Stage Now</span><span style="color: #00a2a4"> - Applies to all 'Levels'. Allocated process stage (input during all process stages - 'Unspecified' (default), 'Completed', 'Production Started', 'Receieved on Site','Delivered', 'Installed').</span></li>
    <li><span style="color: #046888;">Planned Completion Date</span><span style="color: #00a2a4"> - Applies to all 'Levels'. Refer to stages 2, 9, 14 of process map in this document. Date when finished element is due to be completed.</span></li>
    <li><span style="color: #046888;">Planned Delivery Date</span><span style="color: #00a2a4"> - Applies to 'Level 1' only. Date when product is due to be delivered on Site.</span></li>
    <li><span style="color: #046888;">Planned Install Date</span><span style="color: #00a2a4"> - Applies to 'Level 1' only. Date when product is due to be installed in its final location on Site.</span></li>
    <li><span style="color: #046888;">Actual Completion Date</span><span style="color: #00a2a4"> - Applies to all 'Levels'. Refer to stages 2, 9, 14 of process map in this document. Date when finished element is actually completed.</span></li>
    <li><span style="color: #046888;">Actual Delivery Date</span><span style="color: #00a2a4"> - Applies to 'Level 1' only. Date when product is actually delivered to Site.</span></li>
    <li><span style="color: #046888;">Actual Install Date</span><span style="color: #00a2a4"> - Applies to 'Level 1' only. Date when product is actually installed in its final location on Site.</span></li>
    <li><span style="color: #046888;">Free Float</span><span style="color: #00a2a4"> - Applies to 'Level 1' only. Number of days from current date shown on report before item falls on critical path based on planned install date.</span></li>
    <li><span style="color: #046888;">Status Now</span><span style="color: #00a2a4"> - Applies to any 'Level'. Red means delivery has passed the critical date, yellow means delivery was made within critical date, green means delivery was made within planned delivery date.</span></li>
  </ol>
</ng-container>
