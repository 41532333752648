import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { StageService } from '@plasma/services/stage.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { PhaseService } from '@plasma/services/phase.plasma';
import { OrderService } from '@plasma/services/order.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { EmailService } from '@plasma/services/email.plasma';

import { IAgent } from '@plasma/models/agent';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { LoadingService } from '@plasma/components/loading/loading.service';

import { CreatePhaseModalComponent } from '@plasma/components/modal/create-phase-modal.component';
import { AssignUsersModalComponent } from '@plasma/components/modal/assign-users-modal.component';
import { ImportOrdersModalComponent } from '@plasma/components/modal/import-orders-modal.component';

import { Subject, pipe } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';



@Component({
  selector: 'app-stage-status',
  templateUrl: './stage-status.component.html',
  styleUrls: ['./stage-status.component.scss']
})
export class StageStatusComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  id: string;
  /* user: IAgent; */
  stage: any;
  showLoading = false;
  initOpts = {
    width: 450,
    height: 300
  };

  stackedBarChart: any = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // Use axis to trigger tooltip
            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
        }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
        type: 'value',
        show: false
    },
    yAxis: {
        type: 'category',
        data: []
    },
    series: [],
    color: ['#91cc75', '#fac858', '#ee6666']
  };

  rejectedDamagedAssetsChart: any = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // Use axis to trigger tooltip
            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
        }
    },
    legend: {},
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        show: false
    },
    yAxis: {
        type: 'category',
        data: []
    },
    series: [],
    color: ['#ee6666', '#fac858']
  };


  constructor(private stageService: StageService,
              private authService: AuthService,
              private agentService: AgentsService,
              private emailService: EmailService,
              private modal: NzModalService,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private viewContainerRef: ViewContainerRef,
              private loadingService: LoadingService,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    /* this.authService.getUser()
      .subscribe((user: IAgent) => {
        console.log('user/agent', user);
        this.user = user;
    }); */

    this.loadStageStatus();

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadStageStatus(): void {
    this.stageService.getStatus(this.id)
      .subscribe((stage) => {
        // console.log('stage', stage);
        this.stage = stage;

        this.stackedBarChart.legend.data = ['On Time', 'Under Float Days', 'Delayed'];
        this.rejectedDamagedAssetsChart.legend.data = ['Rejected', 'Damaged'];

        const data = {};
        data['onTime'] = [];
        data['underFloatDays'] = [];
        data['delayed'] = [];
        data['rejected'] = [];
        data['damaged'] = [];
        stage.phases.forEach(phase => {
          this.stackedBarChart.yAxis.data.push(phase.name);
          this.rejectedDamagedAssetsChart.yAxis.data.push(phase.name);
          console.log('phase', phase);
          data['onTime'].push(phase['onTime']);
          data['underFloatDays'].push(phase['underFloatDays']);
          data['delayed'].push(phase['delayed']);
          data['rejected'].push(phase['noOfOrdersRejected']);
          data['damaged'].push(phase['noOfOrdersDamaged']);
        });

        this.stackedBarChart.series.push({
              name: 'On Time',
              type: 'bar',
              stack: 'total',
              barGap: 0,
              label: {
                show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['onTime']
          });

        this.stackedBarChart.series.push({
              name: 'Under Float Days',
              type: 'bar',
              stack: 'total',
              label: {
                show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['underFloatDays']
          });

        this.stackedBarChart.series.push({
              barWidth: 40,
              name: 'Delayed',
              type: 'bar',
              stack: 'total',
              label: {
                show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['delayed']
          });

        this.rejectedDamagedAssetsChart.series.push({
            name: 'Rejected',
            type: 'bar',
            stack: 'total',
            label: {
                show: false
            },
            emphasis: {
                focus: 'series'
            },
            data: data['rejected']
        });
        this.rejectedDamagedAssetsChart.series.push({
              barWidth: 40,
              name: 'Damaged',
              type: 'bar',
              stack: 'total',
              label: {
                  show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['damaged']
          });

      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
    });
  }

  onBack() {
    this.location.back();
  }

}
