<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
  <div fxLayoutAlign="center start" id="field-container">
    <ng-template [ngIf]="!displayChart">
      <h5>No Supplier Status Updates Currently Available!</h5>
    </ng-template>
    <ng-template [ngIf]="displayChart">
      <h5>Supplier Orders On Time and Delayed</h5>
    </ng-template>
  </div>
  <div fxLayoutAlign="center start" id="field-container">
    <ng-template [ngIf]="supplierList.length > 0">
      <h5>Supplier Analytics</h5>
    </ng-template>
  </div>
</div>

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
  <div fxLayoutAlign="center start" id="field-container">
    <ng-template [ngIf]="displayChart">
      <div echarts [initOpts]="initOpts" [options]="stackedHorizontalBarChart"></div>
    </ng-template>
  </div>
  <ng-template [ngIf]="supplierList.length > 0">
    <div fxLayoutAlign="center start" id="field-container">
      <nz-table #supplierTable [nzData]="supplierList" style="margin-top: 10px; width: 35vw;">
        <thead>
          <tr>
            <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 10;"><strong>Supplier Name</strong></th>
            <th style="background: #00a2a4; color: white;"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let supplier of supplierTable.data; let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
            <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ supplier }}</td>
            <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">
              <button nz-button (click)="openDialog(supplier)">Find Out More</button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </ng-template>
</div>

  <!-- <nz-row>
    <nz-col [nzSpan]="24">
      {{supplier}}
    </nz-col>
  </nz-row>
  <nz-row [nzGutter]="16">
    <nz-col [nzSpan]="6">
      <nz-statistic [nzValue]="(calculateOnTimeInFull(suppliers[supplier]) | number)!" nzSuffix="%" [nzTitle]="'On Time in Full'"></nz-statistic>
    </nz-col>
    <nz-col [nzSpan]="6">
      <nz-statistic [nzValue]="(calculateRightFirstTime(suppliers[supplier]) | number)!" nzSuffix="%" [nzTitle]="'Right First Time'"></nz-statistic>
    </nz-col>
    <nz-col [nzSpan]="6">
      <nz-statistic [nzValue]="(calculateConformanceToSchedule(suppliers[supplier]) | number)!" nzSuffix="%" [nzTitle]="'Conformance to Schedule'"></nz-statistic>
    </nz-col>
    <nz-col [nzSpan]="6">
      <nz-statistic [nzValue]="(calculateDefectedPer(suppliers[supplier]) | number)!" [nzTitle]="'Defects per 1,000,000'"></nz-statistic>
    </nz-col>
  </nz-row>
  <div class="row"> -->
    <!-- <div class="col-sm-4">
      <ng-template [ngIf]="displayOnTimeInFullChart">
        <div echarts [options]="generateHorizontalBarChart(supplier, calculateOnTimeInFull(suppliers[supplier]))"></div>
      </ng-template>
    </div> -->
    <!-- <div class="col-sm-6">
      <ng-template [ngIf]="displayRightFirstTimeChart">
        <div echarts [options]="generatePieChart(supplier, 'right_first_time', calculateRightFirstTime(suppliers[supplier]))"></div>
      </ng-template>
    </div>
    <div class="col-sm-6">
      <ng-template [ngIf]="displayConformanceChart">
        <div echarts [options]="generatePieChart(supplier, 'conformance_to_schedule', calculateConformanceToSchedule(suppliers[supplier]))"></div>
      </ng-template>
    </div> -->
  <!-- </div>
</ng-container> -->
