export const HELP_SECTIONS = {
    registration: {
        title: 'Registration',
        paragraphs: [
          'Fill in the required fields (fields with *)',
          'If your company is not listed in Account dropdown, then select Create New',
          'After you register you will see a form to create the company/account.',
          'Without the account/company the user will not be able to do anything'
        ],
        imgLinks: ['registration.png', 'create account.png']
    },
    project: {
        title: 'Create a Project',
        paragraphs: ['Fill in the required fields (fields with *)',
        'Specify phases for your project',
        'Assign users which can view and create orders in the project.',
        'Click on View Project in order to access the project.'],
        imgLinks: ['create project.png']
    },
    order: {
        title: 'Create an Order/Requisition',
        paragraphs: [
            'You can create an order/requisition by going to a project, and clicking on "Create Order" button',
            'Fill in the required fields (fields with *)',
            'If you want to add items to the order click on Add Item button on right side.',
            'If you want to remove an item click on - sign against the item',
            'After filling the fields click on Create Order button at the bottom.',
            'You will receive notification, "Order was created successfully"'
        ],
        imgLinks: ['create order.png']
    },
    viewOrder: {
        title: 'View Current Orders to work on',
        paragraphs: [
            'To view orders go to a project, click on "View Project"',
            'You will see list of your current orders'],
        imgLinks: ['orders.png']
    },
    trackingIntelligence: {
        title: 'Provide tracking intelligence and updates to all supply chain participants',
        paragraphs: ['You can view tracking information by going to the list of orders view, and clicking on tracking button'],
        imgLinks: [
            'track-btn.png',
            'order.png'
        ]
    },
    linkBarcode: {
        title: 'Link Bar Code/QR Code to order/requisition on the system',
        paragraphs: [
            'Open an order by clicking on track button against an order',
            'At the order view, at the bottom you will see a list of items related to the order.',
            'Click on any id under the "Id" column to see the details of the item',
            'In the item view, you can add barcode against the "Barcode" field.'
        ],
        imgLinks: [
            'item.png',
            'barcode.png'
        ]
    },
    paymentAuthorization: {
        title: 'Trigger Event (Payment Authorization)',
        paragraphs: ['Follow the steps mentioned under the heading "View Current Orders to Work On", at the right side under Action column click on bank note icon, payment details are displayed and you can click on "Authorize Payment" to send payment.'],
        imgLinks: ['payment.png']
    },
    provenance: {
        title: 'Provenance/Traceability of Component',
        paragraphs: [
            'You can view tracking information by going to the list of orders view, and clicking on provenance button',
            'Provenance will only be available for orders containing doors',
            'If an order does not contain doors, provenance table will be empty',
            'Provenance can be added only by a Supplier, you will have to attach items/assets together to create the chain'
        ],
        imgLinks: [
            'provenance-btn.png',
            'provenance.png',
            'add-provenance.png'
        ]
    },
    uploadDocuments: {
        title: 'Upload documents alongside a status update',
        paragraphs: [
            'Documents can be uploaded for an order by going to the Order view.',
            'Documents for an item can be uploaded by going to the Item view.'
        ],
        imgLinks: [
            'order-document-upload.png',
            'item-document-upload.png'
        ]
    },
    linkedOrders: {
        title: 'Check Linked Orders',
        paragraphs: [
            'You can view orders that are linked together by clicking on linked orders option in the list of orders screen.',
            'Click on linked orders button.'
        ],
        imgLinks: [
            'linked orders option.png',
            'linked orders.png'
        ]
    },
    projectStatus: {
        title: 'Check Project Status',
        paragraphs: [
            'Follow the steps from "View Current Orders to work on"',
            'On the top you should see "Project Status" button, click on it.',
            'This will display current project status.'
        ],
        imgLinks: [
            'project status option.png',
            'project status.png'
        ]
    },
    suppliersStatus: {
        title: 'Check Suppliers Status',
        paragraphs: [
            'You can check suppliers status by clicking on the left hand side main menu.'
        ],
        imgLinks: [
            'suppliers status.png'
        ]
    },
    suppliersNetwork: {
        title: 'Access Suppliers Network',
        paragraphs: [
            'You can access suppliers network by clicking on the left hand side main menu.'
        ],
        imgLinks: ['suppliers network.png']
    },
    installation: {
        title: 'Installation',
        paragraphs: [
            'Open the installation file (plasma.apk) on your smartphone',
            'After openning the file, it will prompt you if you want to install the app',
            'Click on "Install"',
            'After installation, search for an app known as "Plasma"'
        ],
        imgLinks: ['mobile-app.jpg']
    },
    login: {
        title: 'Log in to the Mobile App',
        paragraphs: [
            'Open the app',
            'Fill in the details on the login screen'
        ],
        imgLinks: ['mobile-login.jpg']
    },
    scanBarcode: {
        title: 'Scan Bar Code/QR Code',
        paragraphs: [
            'Perform the login steps',
            'When you will login, app will take you to the scan screen',
            'Click on the bottom right button',
            'Click on the QR code icon, to scan barcode',
            'If the barcode is registered, the app will display the details of the item'
        ],
        imgLinks: [
            'mobile-scan-barcode.jpg',
            'mobile-item.jpg'
        ]
    },
    uploadPictures: {
        title: 'Upload picture alongside a status update to the blockchain',
        paragraphs: [
            'Perform the steps "Scan Bar Code/QR Code"',
            'Only a contractor can take pictures at the moment',
            'When you will change the pre-state or post-state of the Item to either (Damaged or Undamaged), it will open up your camera so you can take picture',
            'The picture taken will be displayed at the bottom of the screen',
            'When you will click "Update" the picture will be uploaded and item details will be updated'
        ],
        imgLinks: [
            'mobile-pre-state-update.jpg',
            'mobile-take-picture.jpg',
            'mobile-asset-updated.jpg'
        ]
    },
    inventory: {
        title: 'Add Inventory Items',
        paragraphs: [
            'Inventory can only be accessed by a Supplier.',
            'Go to Inventory if you would like to add raw material',
            'Click on Inventory then on Add Item',
            'Fill in the following form to add a Raw Material',
        ],
        imgLinks: [
            'inventory-btn.png',
            'add-inventory-item.png',
            'add-item-form.png'
        ]
    }
};
