<!-- <router-outlet name="dashboard"></router-outlet> -->
<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <a href="https://app.trackchain.net/" target="_blank">
        <img src="assets/img/logo.png" alt="logo">
        <h1>TrackChain</h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter *ngIf="(auth.getUser() | async)?.role?.type === 'BUYER'">
            <a [routerLink]="[{ outlets: { dashboard: ['account'] } }]">{{(auth.getUser() | async)?.account?.name !== '' ? (auth.getUser() | async)?.account?.name : (auth.getUser() | async)?.name}}</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="(auth.getUser() | async)?.role?.type === 'SUPPLIER'">
            <a [routerLink]="[{ outlets: { dashboard: ['orders'] } }]">Orders</a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter>
            <a [routerLink]="[{ outlets: { dashboard: ['items'] } }]">Items</a>
          </li> -->
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="[{ outlets: { dashboard: ['suppliers'] } }]">Suppliers</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="(auth.getUser() | async)?.role?.type === 'BUYER'">
            <a [routerLink]="[{ outlets: { dashboard: ['suppliers-status'] } }]">Suppliers Status</a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter *ngIf="(auth.getUser() | async)?.role?.type === 'BUYER'">
            <a href="http://api.trackchain.net:8045" target="_blank">Suppliers Network</a>
          </li> -->
          <li nz-menu-item nzMatchRouter *ngIf="(auth.getUser() | async)?.canManageUsers">
            <a [routerLink]="[{ outlets: { dashboard: ['users'] } }]">Users</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="[{ outlets: { dashboard: ['help'] } }]">Help</a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-a">Supplier A</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-b">Supplier B</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-c">Supplier C</a>
          </li> -->
        </ul>
      </li>
      <!-- <li nz-submenu nzOpen nzTitle="Form" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a>Basic Form</a>
          </li>
        </ul>
      </li> -->
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <ul nz-menu nzTheme="light" nzMode="horizontal" class="header-menu">
          <li nz-menu-item><span class="header-trigger" (click)="isCollapsed = !isCollapsed"><i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i></span></li>
          <li nz-menu-item class="float-right">
              <nz-avatar nz-dropdown [nzDropdownMenu]="menu4" nzIcon="user" style="background: #001529"></nz-avatar>
          </li>
        </ul>
        <nz-dropdown-menu #menu4="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item [routerLink]="[{ outlets: { dashboard: ['profile'] } }]">Profile</li>
            <li nz-menu-item (click)="logout()">Logout</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </nz-header>
    <nz-content>
      <app-loading>
        <div class="inner-content">
          <router-outlet name="dashboard"></router-outlet>
          <!-- <form nz-form [nzLayout]="'horizontal'" [formGroup]="validateForm" (ngSubmit)="submitForm(validateForm)">
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="orderNo">Order No</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid Order No!">
                <input nz-input formControlName="orderNo" id="orderNo" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="supplier" nzRequired>Supplier</nz-form-label>
              <nz-form-control
                [nzValidateStatus]="validateForm.controls['supplier']"
                nzErrorTip="Please select your supplier!"
                [nzSm]="14" [nzXs]="24"
              >
                <nz-select formControlName="supplier">
                    <nz-option nzLabel="Supplier A" nzValue="'Supplier A'"></nz-option>
                    <nz-option nzLabel="Supplier B" nzValue="'Supplier B'"></nz-option>
                    <nz-option nzLabel="Supplier C" nzValue="'Supplier C'"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="quantity">Quantity</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid Quantity!">
                <input nz-input formControlName="quantity" id="quantity" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="unitPrice">Unit Price</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid Price!">
                <input nz-input formControlName="unitPrice" id="unitPrice" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="agreedPrice">Agreed Price (Total)</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid Price!">
                <input nz-input formControlName="agreedPrice" id="agreedPrice" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="deliveryLocation">Delivery Location</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not a location!">
                <input nz-input formControlName="deliveryLocation" id="deliveryLocation" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="completionDate">Date of Completion</nz-form-label>
              <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="The input is not a valid date!">
                <nz-date-picker formControlName="completionDate" id="completionDate"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="description">Description</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is empty!">
                <textarea rows="4" nz-input formControlName="description" id="description"></textarea>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="paymentTerms">Payment Terms</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is empty!">
                <textarea rows="4" nz-input formControlName="paymentTerms" id="paymentTerms"></textarea>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="discounts">Any discounts to be applied</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not a valid value!">
                <input nz-input formControlName="discounts" id="discounts" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="sku">Model Number/SKU</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24">
                <input nz-input formControlName="sku" id="sku" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="billingAddress">Company billing address</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please enter billing address!">
                <textarea rows="4" nz-input formControlName="billingAddress" id="billingAddress"></textarea>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item nz-row class="register-area">
              <nz-form-control [nzSpan]="14" [nzOffset]="8">
                <button nz-button nzType="primary">Create Order</button>
              </nz-form-control>
            </nz-form-item>
          </form> -->
        </div>
      </app-loading>
    </nz-content>
  </nz-layout>
</nz-layout>
