<!-- <router-outlet name="dashboard"></router-outlet> -->
<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <a href="https://app.trackchain.net/" target="_blank">
        <img src="assets/img/logo.png" alt="logo">
        <h1>TrackChain</h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter *ngIf="(auth.getUser() | async)?.role?.type === 'BUYER'">
            <a [routerLink]="[{ outlets: { dashboard: ['account'] } }]">{{(auth.getUser() | async)?.account?.name !== '' ? (auth.getUser() | async)?.account?.name : (auth.getUser() | async)?.name}}</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="(auth.getUser() | async)?.role?.type === 'SUPPLIER' || (auth.getUser() | async)?.role?.type === 'SERVICE_PROVIDER'">
            <a [routerLink]="[{ outlets: { dashboard: ['orders'] } }]">Requisitions</a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <ul nz-menu nzTheme="light" nzMode="horizontal" class="header-menu">
          <li nz-menu-item><span class="header-trigger" (click)="isCollapsed = !isCollapsed"><i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i></span></li>
          <li nz-menu-item class="float-right">
              <nz-avatar nz-dropdown [nzDropdownMenu]="menu4" nzIcon="user" style="background: #001529"></nz-avatar>
          </li>
        </ul>
        <nz-dropdown-menu #menu4="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item [routerLink]="[{ outlets: { dashboard: ['profile'] } }]">Profile</li>
            <li nz-menu-item (click)="logout()">Logout</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </nz-header>
    <nz-content>
      <app-loading>
        <div class="inner-content">
          <router-outlet name="dashboard"></router-outlet>
        </div>
      </app-loading>
    </nz-content>
  </nz-layout>
</nz-layout>
