<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon nzTitile="Item"></nz-page-header>
<div fxLayout="row" fxLayoutAlign="start center" style="background-color: #0d47a1; margin-bottom: 20px">
    
    <div fxLayoutAlign="center center" style="background-color: #2196f3; padding: 10px 10px 0 10px;">
      <button mat-icon-button class="example-icon" style="color: white;" aria-label="Example icon-button with menu icon" fxFlexFill>
        <fa-icon [icon]="['fas', 'list-alt']" size="2x"></fa-icon>
      </button>
    </div>
    
    <div fxLayoutAlign="center center" style="padding-left: 1em;">
      <span fxFlexFill><h3 class="mat-h2" style="margin-bottom: 0; color: white;">Detailed Item Information</h3></span>
      <span class="example-spacer"></span>
    </div>
  
</div>

<ng-container *ngIf="asset" class="mat-app-background">
  <h4 class="mat-h2" color="primary" style="color: #2196f3; margin-bottom: 0px;">{{asset.get('asset_type').value}} - {{asset.get('id').value}} </h4>
  <mat-divider style="margin-bottom: 20px;"></mat-divider>
  <form novalidate [formGroup]="asset" (ngSubmit)="onSubmit(asset)">

    <input type="text" [hidden]="true" formControlName="id" class="form-control form-control-sm" id="id">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Item Type</mat-label>
          <input matInput required formControlName="asset_type">
        </mat-form-field>
      </div>

      <ng-template [ngIf]="asset.get('owners')">
  
        <ng-container formArrayName="owners" *ngFor="let owner of owners.controls; let i = index;">
          <ng-container [formGroupName]="i">
            <input type="text" [hidden]="true" formControlName="id" class="form-control form-control-sm" id="id">
            <div fxLayoutAlign="center center" id="field-container">
              <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>Owner Name</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
            <input type="text" [hidden]="true" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
          </ng-container>
        </ng-container>
      </ng-template>
      
      <ng-template [ngIf]="asset.get('locations')">
        <!-- <nz-divider nzText="Location" nzOrientation="left"></nz-divider> -->
        <ng-container formArrayName="locations" *ngFor="let location of locations.controls; let i = index;">
          <ng-container [formGroupName]="i">
            <input type="text" [hidden]="true" formControlName="latitude" class="form-control form-control-sm" id="latitude">
            <input type="text" [hidden]="true" formControlName="longitude" class="form-control form-control-sm" id="longitude">
            <div fxLayoutAlign="center center" id="field-container">
              <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>Location</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
            <input type="text" [hidden]="true" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
          </ng-container>
        </ng-container>
      </ng-template>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Description</mat-label>
          <input matInput formControlName="description">
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Float Days</mat-label>
          <input matInput type="number" min="0" formControlName="float_days">
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>UOQ</mat-label>
          <input matInput formControlName="uoq">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Unit</mat-label>
          <input matInput formControlName="unit">
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Quantity</mat-label>
          <input matInput required formControlName="quantity">
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Price</mat-label>
          <span matPrefix>&pound;</span>
          <input matInput required formControlName="price">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Discount</mat-label>
          <input matInput formControlName="discount">
        </mat-form-field>
      </div>

      <ng-template [ngIf]="asset.get('supplier')">
        <input type="text" [hidden]="true" formControlName="agent_id" class="form-control form-control-sm" id="agent_id">
        <div fxLayoutAlign="center center" id="field-container" formGroupName="supplier">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Supplier</mat-label>
            <input matInput required formControlName="name">
          </mat-form-field>
        </div>
        <input type="text" [hidden]="true" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
      </ng-template>

      <ng-template [ngIf]="asset.get('manufacturer')?.get('name').value !== ''">
        <input type="text" [hidden]="true" formControlName="agent_id" class="form-control form-control-sm" id="agent_id">
        <div fxLayoutAlign="center center" id="field-container" formGroupName="manufacturer">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Manufacturer</mat-label>
            <input matInput required formControlName="name">
          </mat-form-field>
        </div>
        <input type="text" [hidden]="true" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
      </ng-template>

      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Created On</mat-label>
          <input matInput type="datetime-local" [value]="asset.get('created_on').value | date: 'yyyy-MM-ddTHH:mm'" formControlName="created_on">
          <!-- <input matInput type="datetime-local" [value]="asset.get('created_on').value | date: 'yyyy-MM-dd, h:mm a'" formControlName="created_on"> -->
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
    
      <ng-container formGroupName="pre_state">
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Pre-State</mat-label>
            <mat-select formControlName="state">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let state of states" [value]="state?.value">{{state?.title}}</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error>
            <mat-hint>{{animalControl.value?.sound}}</mat-hint> -->
          </mat-form-field>
          <!-- <button mat-button (click)="openPreStateDialog()">Open dialog</button> -->
        </div>

        <ng-container formArrayName="images">
          <div class="form-row" *ngIf="preStateImages.controls?.length > 0 && preStateImages?.controls[0]?.controls?.image_url?.value">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="state" class="col-sm-4 col-form-label"></label>
                <div class="col-sm-2" *ngFor="let image of preStateImages.controls; let i = index;">
                  <img class="mr-3 img-fluid img-thumbnail" [src]="image?.controls?.image_url?.value" width="150" height="150" [alt]="image?.controls?.image_url?.value">
                  <!-- <label>{{image?.controls?.location?.controls?.name?.value}}</label>
                  <label>{{image?.controls?.location?.controls?.latitude?.value}}</label>
                  <label>{{image?.controls?.location?.controls?.longitude?.value}}</label>
                  <label>{{image?.controls?.location?.controls?.timestamp?.value}}</label> -->
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container formControlArray="documents">
          <div class="form-row" *ngIf="preStateDocuments?.controls?.length > 0">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="state" class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-5" *ngFor="let control of preStateDocuments?.controls;">
                      <a nz-button download target="_blank" [href]="control?.value" nzType="link">
                        <fa-icon [icon]="['fas', (control?.value?.split('.')[4] === 'docx' || control?.value?.split('.')[4] === 'doc' || control?.value?.split('.')[4] === 'dto') ? 'file-word' : (control?.value?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="3x"></fa-icon>
                      </a>
                      {{extractFilename(control?.value)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-sm-12">
                <nz-upload nzType="drag" class="upload upload-list-inline" nzListType="picture" [(nzFileList)]="preStateFiles" [nzBeforeUpload]="beforePreStateUpload" [nzMultiple]="true" (nzChange)="handleChange($event)">
                  <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                  </p>
                  <p class="ant-upload-text">Click or drag file to this area to upload</p>
                  <p class="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                  </p>
                </nz-upload>
                <button
                  nz-button
                  [nzType]="'primary'"
                  [nzLoading]="preStateUploading"
                  (click)="handleUpload($event, 'pre_state')"
                  [disabled]="preStateFiles.length == 0"
                  style="margin-top: 16px"
                >
                  {{ preStateUploading ? 'Uploading' : 'Start Upload' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
      <ng-container formGroupName="post_state">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Post-State</mat-label>
            <mat-select formControlName="state">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let state of states" [value]="state?.value">{{state?.title}}</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error>
            <mat-hint>{{animalControl.value?.sound}}</mat-hint> -->
          </mat-form-field>
        </div>

        <ng-container formControlArray="images">
          <div class="form-row" *ngIf="postStateImages.controls?.length > 0 && postStateImages?.controls[0]?.controls?.image_url?.value">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="state" class="col-sm-4 col-form-label"></label>
                <div class="col-sm-2" *ngFor="let image of postStateImages.controls; let i = index;">
                  <img [src]="image?.controls?.image_url?.value" class="mr-3 img-fluid img-thumbnail" width="150" height="150" [alt]="image?.controls?.image_url?.value">
                  <!-- <label>{{image?.controls?.location?.controls?.name?.value}}</label>
                  <label>{{image?.controls?.location?.controls?.latitude?.value}}</label>
                  <label>{{image?.controls?.location?.controls?.longitude?.value}}</label>
                  <label>{{image?.controls?.location?.controls?.timestamp?.value}}</label> -->
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container formControlArray="documents">
          <div class="form-row" *ngIf="postStateDocuments?.controls?.length > 0">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="state" class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-2" *ngFor="let control of postStateDocuments?.controls; let i = index;">
                      <a nz-button download target="_blank" [href]="control?.value" nzType="link">
                        <fa-icon [icon]="['fas', (control?.value?.split('.')[4] === 'docx' || control?.value?.split('.')[4] === 'doc' || control?.value?.split('.')[4] === 'dto') ? 'file-word' : (control?.value?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="3x"></fa-icon>
                      </a>
                      {{extractFilename(control?.value)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-sm-12">
                <nz-upload nzType="drag" class="upload upload-list-inline" nzListType="picture" [(nzFileList)]="postStateFiles" [nzBeforeUpload]="beforePostStateUpload" [nzMultiple]="true" (nzChange)="handleChange($event)">
                  <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                  </p>
                  <p class="ant-upload-text">Click or drag file to this area to upload</p>
                  <p class="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                  </p>
                </nz-upload>
                <button
                  nz-button
                  [nzType]="'primary'"
                  [nzLoading]="postStateUploading"
                  (click)="handleUpload($event, 'post_state')"
                  [disabled]="postStateFiles.length == 0"
                  style="margin-top: 16px"
                >
                  {{ postStateUploading ? 'Uploading' : 'Start Upload' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
      <ng-container formGroupName="stage">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Stage</mat-label>
            <mat-select formControlName="stage">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let stage of stages" [value]="stage?.value">{{stage?.title}}</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error>
            <mat-hint>{{animalControl.value?.sound}}</mat-hint> -->
          </mat-form-field>
        </div>
        <ng-container formControlArray="documents">
          <div class="form-row" *ngIf="stageDocuments?.value?.length > 0">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="state" class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-2" *ngFor="let control of stageDocuments?.controls; let i = index;">
                      <a nz-button download target="_blank" [href]="control?.value" nzType="link">
                        <fa-icon [icon]="['fas', (control?.value?.split('.')[4] === 'docx' || control?.value?.split('.')[4] === 'doc' || control?.value?.split('.')[4] === 'dto') ? 'file-word' : (control?.value?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="3x"></fa-icon>
                      </a>
                      {{extractFilename(control?.value)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-sm-12">
                <nz-upload nzType="drag" class="upload upload-list-inline" nzListType="picture" [(nzFileList)]="stageFiles" [nzBeforeUpload]="beforeStageUpload" [nzMultiple]="true" (nzChange)="handleChange($event)">
                  <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                  </p>
                  <p class="ant-upload-text">Click or drag file to this area to upload</p>
                  <p class="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                  </p>
                </nz-upload>
                <button
                  nz-button
                  [nzType]="'primary'"
                  [nzLoading]="stageUploading"
                  (click)="handleUpload($event, 'stage')"
                  [disabled]="stageFiles.length == 0"
                  style="margin-top: 16px"
                >
                  {{ stageUploading ? 'Uploading' : 'Start Upload' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    
      
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Mode of Transport</mat-label>
          <mat-select formControlName="mode_of_transport">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let mode of modeOfTransport" [value]="mode?.value">
              {{mode?.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field floatLabel="always" appearance="fill" fxFlexFill>
          <mat-label>Accepted</mat-label>
          <mat-slide-toggle color="primary" (change)="assetAccepted($event)" formControlName="is_accepted"></mat-slide-toggle>
          <textarea matInput hidden></textarea>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field floatLabel="always" appearance="fill" fxFlexFill>
          <mat-label>Rejected</mat-label>
          <mat-slide-toggle color="primary" (change)="assetRejected($event)" formControlName="is_rejected"></mat-slide-toggle>
          <textarea matInput hidden></textarea>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Barcode</mat-label>
          <input matInput formControlName="barcode">
        </mat-form-field>
      </div>
      <ng-container formGroupName="planned_dates">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Planned Completion Date</mat-label>
            <input matInput [matDatepicker]="plannedCompletionDate" formControlName="planned_completion_date">
            <mat-datepicker-toggle matSuffix [for]="plannedCompletionDate">
              <!-- <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon> -->
            </mat-datepicker-toggle>
            <mat-datepicker #plannedCompletionDate></mat-datepicker>
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Planned Delivery Date</mat-label>
            <input matInput [matDatepicker]="plannedDeliveryDate" formControlName="planned_delivery_date">
            <mat-datepicker-toggle matSuffix [for]="plannedDeliveryDate"></mat-datepicker-toggle>
            <mat-datepicker #plannedDeliveryDate></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
      
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <ng-container formGroupName="planned_dates">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Planned Installed Date</mat-label>
            <input matInput [matDatepicker]="plannedInstalledDate" formControlName="planned_installed_date">
            <mat-datepicker-toggle matSuffix [for]="plannedInstalledDate"></mat-datepicker-toggle>
            <mat-datepicker #plannedInstalledDate></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container formGroupName="actual_dates">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Actual Completion Date</mat-label>
            <input matInput [matDatepicker]="actualCompletionDate" formControlName="actual_completion_date">
            <mat-datepicker-toggle matSuffix [for]="actualCompletionDate"></mat-datepicker-toggle>
            <mat-datepicker #actualCompletionDate></mat-datepicker>
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Actual Delivery Date</mat-label>
            <input matInput [matDatepicker]="actualDeliveryDate" formControlName="actual_delivery_date">
            <mat-datepicker-toggle matSuffix [for]="actualDeliveryDate"></mat-datepicker-toggle>
            <mat-datepicker #actualDeliveryDate></mat-datepicker>
          </mat-form-field>
        </div>
    </ng-container>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <ng-container formGroupName="actual_dates">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Actual Installed Date</mat-label>
            <input matInput [matDatepicker]="actualInstalledDate" formControlName="actual_installed_date">
            <mat-datepicker-toggle matSuffix [for]="actualInstalledDate"></mat-datepicker-toggle>
            <mat-datepicker #actualInstalledDate></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
      
      <ng-container formGroupName="order">
        <input type="text" [hidden]="true" formControlName="id" class="form-control form-control-sm" id="id">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Order No</mat-label>
            <input matInput required formControlName="order_no">
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Created On</mat-label>
            <input matInput type="datetime-local" [value]="asset.get('order').get('timestamp').value | date: 'yyyy-MM-ddTHH:mm'" formControlName="timestamp">
          </mat-form-field>
        </div>
      </ng-container>
      
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
      <!-- <div fxLayoutAlign="center center" id="field-container">
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
      </div> -->
      
      <div fxLayoutAlign="center center" id="field-container">
        <button mat-raised-button fxFlexFill type="submit" [disabled]="asset.invalid">Update</button>
      </div>
    </div>

    <!-- <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="asset_type" class="col-sm-4 col-form-label required">Item Type</label>
          <div class="col-sm-8">
            <input type="text" [value]="asset.get('asset_type').value | titlecase" formControlName="asset_type" class="form-control form-control-sm" id="asset_type">
          </div>
        </div>
      </div>
    </div> -->

    <!-- <ng-template [ngIf]="asset.get('owners')">
      <nz-divider nzText="Owner" nzOrientation="left"></nz-divider>

      <ng-container formArrayName="owners" *ngFor="let owner of owners.controls; let i = index;">
        <ng-container [formGroupName]="i">
          <input type="text" [hidden]="true" formControlName="id" class="form-control form-control-sm" id="id">
          <div class="form-row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Owner Name</label>
                <div class="col-sm-8">
                  <input type="text" formControlName="name" class="form-control form-control-sm" id="name">
                </div>
              </div>
            </div>
          </div>
          <input type="text" [hidden]="true" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template [ngIf]="asset.get('locations')">
      <nz-divider nzText="Location" nzOrientation="left"></nz-divider>
      <ng-container formArrayName="locations" *ngFor="let location of locations.controls; let i = index;">
        <ng-container [formGroupName]="i">
          <input type="text" [hidden]="true" formControlName="latitude" class="form-control form-control-sm" id="latitude">
          <input type="text" [hidden]="true" formControlName="longitude" class="form-control form-control-sm" id="longitude">
          <div class="form-row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Location</label>
                <div class="col-sm-8">
                  <input type="text" formControlName="name" class="form-control form-control-sm" id="name">
                </div>
              </div>
            </div>
          </div>
          <input type="text" [hidden]="true" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
        </ng-container>
      </ng-container>
    </ng-template>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="description" class="col-sm-4 col-form-label">Description</label>
          <div class="col-sm-8">
            <input type="text" formControlName="description" class="form-control form-control-sm" id="description">
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="float_days" class="col-sm-4 col-form-label">Float Days</label>
          <div class="col-sm-8">
            <input type="number" min="0" formControlName="float_days" class="form-control form-control-sm" id="float_days">
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="uoq" class="col-sm-4 col-form-label">UOQ</label>
          <div class="col-sm-8">
            <input type="text" formControlName="uoq" class="form-control form-control-sm" id="uoq">
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="unit" class="col-sm-4 col-form-label">Unit</label>
          <div class="col-sm-8">
            <input type="text" formControlName="unit" class="form-control form-control-sm" id="unit">
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="quantity" class="col-sm-4 col-form-label required">Quantity</label>
          <div class="col-sm-8">
            <input type="text" formControlName="quantity" class="form-control form-control-sm" id="quantity">
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="price" class="col-sm-4 col-form-label required">Price</label>
          <div class="col-sm-8">
            <input type="text" formControlName="price" class="form-control form-control-sm" id="price">
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="discount" class="col-sm-4 col-form-label">Discount</label>
          <div class="col-sm-8">
            <input type="text" formControlName="discount" class="form-control form-control-sm" id="discount">
          </div>
        </div>
      </div>
    </div>

    <ng-template [ngIf]="asset.get('supplier')">
      <input type="text" [hidden]="true" formControlName="agent_id" class="form-control form-control-sm" id="agent_id">
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row" formGroupName="supplier">
            <label for="supplier" class="col-sm-4 col-form-label required">Supplier</label>
            <div class="col-sm-8">
              <input type="text" formControlName="name" class="form-control form-control-sm" id="supplier">
            </div>
          </div>
        </div>
      </div>
      <input type="text" [hidden]="true" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
    </ng-template>

    <ng-template [ngIf]="asset.get('manufacturer')?.get('name').value !== ''">
      <input type="text" [hidden]="true" formControlName="agent_id" class="form-control form-control-sm" id="agent_id">
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row" formGroupName="manufacturer">
            <label for="manufacturer" class="col-sm-4 col-form-label required">Manufacturer</label>
            <div class="col-sm-8">
              <input type="text" formControlName="name" class="form-control form-control-sm" id="manufacturer">
            </div>
          </div>
        </div>
      </div>
      <input type="text" [hidden]="true" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
    </ng-template>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="timestamp" class="col-sm-4 col-form-label">Created on</label>
          <div class="col-sm-8">
            <input type="text" [value]="asset.get('created_on').value | date: 'yyyy-MM-dd, h:mm a'" formControlName="created_on" class="form-control form-control-sm" id="timestamp">
          </div>
        </div>
      </div>
    </div>

    <ng-container formGroupName="pre_state">
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="state" class="col-sm-4 col-form-label">Pre-State</label>
            <div class="col-sm-8">
              <select formControlName="state" class="form-control form-control-sm custom-select custom-select-sm" id="state">
                <option *ngFor="let state of states" [value]="state?.value">{{state?.title}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ng-container formArrayName="images">
        <div class="form-row" *ngIf="preStateImages.controls?.length > 0 && preStateImages?.controls[0]?.controls?.image_url?.value">
          <div class="col-md-12">
            <div class="form-group row">
              <label for="state" class="col-sm-4 col-form-label"></label>
              <div class="col-sm-2" *ngFor="let image of preStateImages.controls; let i = index;">
                <img class="mr-3 img-fluid img-thumbnail" [src]="image?.controls?.image_url?.value" width="150" height="150" [alt]="image?.controls?.image_url?.value">
                <label>{{image?.controls?.location?.controls?.name?.value}}</label>
                <label>{{image?.controls?.location?.controls?.latitude?.value}}</label>
                <label>{{image?.controls?.location?.controls?.longitude?.value}}</label>
                <label>{{image?.controls?.location?.controls?.timestamp?.value}}</label>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container formControlArray="documents">
        <div class="form-row" *ngIf="preStateDocuments?.controls?.length > 0">
          <div class="col-md-12">
            <div class="form-group row">
              <label for="state" class="col-sm-4 col-form-label"></label>
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-5" *ngFor="let control of preStateDocuments?.controls;">
                    <a nz-button download target="_blank" [href]="control?.value" nzType="link">
                      <fa-icon [icon]="['fas', (control?.value?.split('.')[4] === 'docx' || control?.value?.split('.')[4] === 'doc' || control?.value?.split('.')[4] === 'dto') ? 'file-word' : (control?.value?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="3x"></fa-icon>
                    </a>
                    {{extractFilename(control?.value)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="offset-sm-4 col-sm-8">
              <nz-upload nzType="drag" class="upload upload-list-inline" nzListType="picture" [(nzFileList)]="preStateFiles" [nzBeforeUpload]="beforePreStateUpload" [nzMultiple]="true" (nzChange)="handleChange($event)">
                <p class="ant-upload-drag-icon">
                  <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Click or drag file to this area to upload</p>
                <p class="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                </p>
              </nz-upload>
              <button
                nz-button
                [nzType]="'primary'"
                [nzLoading]="preStateUploading"
                (click)="handleUpload($event, 'pre_state')"
                [disabled]="preStateFiles.length == 0"
                style="margin-top: 16px"
              >
                {{ preStateUploading ? 'Uploading' : 'Start Upload' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container formGroupName="post_state">
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="state" class="col-sm-4 col-form-label">Post-State</label>
            <div class="col-sm-8">
              <select formControlName="state" class="form-control form-control-sm custom-select custom-select-sm" id="state">
                <option *ngFor="let state of states" [value]="state?.value">{{state?.title}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ng-container formControlArray="images">
        <div class="form-row" *ngIf="postStateImages.controls?.length > 0 && postStateImages?.controls[0]?.controls?.image_url?.value">
          <div class="col-md-12">
            <div class="form-group row">
              <label for="state" class="col-sm-4 col-form-label"></label>
              <div class="col-sm-2" *ngFor="let image of postStateImages.controls; let i = index;">
                <img [src]="image?.controls?.image_url?.value" class="mr-3 img-fluid img-thumbnail" width="150" height="150" [alt]="image?.controls?.image_url?.value">
                <label>{{image?.controls?.location?.controls?.name?.value}}</label>
                <label>{{image?.controls?.location?.controls?.latitude?.value}}</label>
                <label>{{image?.controls?.location?.controls?.longitude?.value}}</label>
                <label>{{image?.controls?.location?.controls?.timestamp?.value}}</label>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container formControlArray="documents">
        <div class="form-row" *ngIf="postStateDocuments?.controls?.length > 0">
          <div class="col-md-12">
            <div class="form-group row">
              <label for="state" class="col-sm-4 col-form-label"></label>
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-2" *ngFor="let control of postStateDocuments?.controls; let i = index;">
                    <a nz-button download target="_blank" [href]="control?.value" nzType="link">
                      <fa-icon [icon]="['fas', (control?.value?.split('.')[4] === 'docx' || control?.value?.split('.')[4] === 'doc' || control?.value?.split('.')[4] === 'dto') ? 'file-word' : (control?.value?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="3x"></fa-icon>
                    </a>
                    {{extractFilename(control?.value)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="offset-sm-4 col-sm-8">
              <nz-upload nzType="drag" class="upload upload-list-inline" nzListType="picture" [(nzFileList)]="postStateFiles" [nzBeforeUpload]="beforePostStateUpload" [nzMultiple]="true" (nzChange)="handleChange($event)">
                <p class="ant-upload-drag-icon">
                  <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Click or drag file to this area to upload</p>
                <p class="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                </p>
              </nz-upload>
              <button
                nz-button
                [nzType]="'primary'"
                [nzLoading]="postStateUploading"
                (click)="handleUpload($event, 'post_state')"
                [disabled]="postStateFiles.length == 0"
                style="margin-top: 16px"
              >
                {{ postStateUploading ? 'Uploading' : 'Start Upload' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container formGroupName="stage">
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="stage" class="col-sm-4 col-form-label">Stage</label>
            <div class="col-sm-8">
              <select formControlName="stage" class="form-control form-control-sm custom-select custom-select-sm" id="stage">
                <option *ngFor="let stage of stages" [value]="stage?.value">{{stage?.title}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ng-container formControlArray="documents">
        <div class="form-row" *ngIf="stageDocuments?.value?.length > 0">
          <div class="col-md-12">
            <div class="form-group row">
              <label for="state" class="col-sm-4 col-form-label"></label>
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-2" *ngFor="let control of stageDocuments?.controls; let i = index;">
                    <a nz-button download target="_blank" [href]="control?.value" nzType="link">
                      <fa-icon [icon]="['fas', (control?.value?.split('.')[4] === 'docx' || control?.value?.split('.')[4] === 'doc' || control?.value?.split('.')[4] === 'dto') ? 'file-word' : (control?.value?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="3x"></fa-icon>
                    </a>
                    {{extractFilename(control?.value)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="offset-sm-4 col-sm-8">
              <nz-upload nzType="drag" class="upload upload-list-inline" nzListType="picture" [(nzFileList)]="stageFiles" [nzBeforeUpload]="beforeStageUpload" [nzMultiple]="true" (nzChange)="handleChange($event)">
                <p class="ant-upload-drag-icon">
                  <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Click or drag file to this area to upload</p>
                <p class="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                </p>
              </nz-upload>
              <button
                nz-button
                [nzType]="'primary'"
                [nzLoading]="stageUploading"
                (click)="handleUpload($event, 'stage')"
                [disabled]="stageFiles.length == 0"
                style="margin-top: 16px"
              >
                {{ stageUploading ? 'Uploading' : 'Start Upload' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="mode_of_transport" class="col-sm-4 col-form-label">Mode of Transport</label>
          <div class="col-sm-8">
            <select formControlName="mode_of_transport" class="form-control form-control-sm custom-select custom-select-sm" id="mode_of_transport">
              <option *ngFor="let mode of modeOfTransport" [value]="mode?.value">{{mode?.title}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label" for="is_accepted">Accepted</label>
          <div class="col-sm-8">
            <nz-switch id="is_accepted" (change)="assetAccepted($event)" formControlName="is_accepted"></nz-switch>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label" for="is_rejected">Rejected</label>
          <div class="col-sm-8">
            <nz-switch id="is_rejected" (change)="assetRejected($event)" formControlName="is_rejected"></nz-switch>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="barcode" class="col-sm-4 col-form-label">Barcode</label>
          <div class="col-sm-8">
            <input type="text" formControlName="barcode" class="form-control form-control-sm" id="barcode">
          </div>
        </div>
      </div>
    </div>

    <ng-container formGroupName="planned_dates">
      <nz-divider nzText="Planned Dates" nzOrientation="left"></nz-divider>
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="planned_completion_date" class="col-sm-4 col-form-label">Completion Date</label>
            <div class="col-sm-8">
              <input #datepickerYMD="bsDatepicker" bsDatepicker  formControlName="planned_completion_date" class="form-control form-control-sm" id="planned_completion_date" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }">
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="planned_delivery_date" class="col-sm-4 col-form-label">Delivery Date</label>
            <div class="col-sm-8">
              <input type="text" #datepickerYMD="bsDatepicker" bsDatepicker formControlName="planned_delivery_date" class="form-control form-control-sm" id="planned_delivery_date" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }">
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="planned_installed_date" class="col-sm-4 col-form-label">Installed Date</label>
            <div class="col-sm-8">
              <input type="text" #datepickerYMD="bsDatepicker" bsDatepicker formControlName="planned_installed_date" class="form-control form-control-sm" id="planned_installed_date" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }">
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container formGroupName="actual_dates">
      <nz-divider nzText="Actual Dates" nzOrientation="left"></nz-divider>
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="actual_completion_date" class="col-sm-4 col-form-label">Completion Date</label>
            <div class="col-sm-8">
              <input type="text" #datepickerYMD="bsDatepicker" bsDatepicker formControlName="actual_completion_date" class="form-control form-control-sm" id="actual_completion_date" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }">
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="actual_delivery_date" class="col-sm-4 col-form-label">Delivery Date</label>
            <div class="col-sm-8">
              <input type="text" #datepickerYMD="bsDatepicker" bsDatepicker formControlName="actual_delivery_date" class="form-control form-control-sm" id="actual_delivery_date" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }">
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="actual_installed_date" class="col-sm-4 col-form-label">Installed Date</label>
            <div class="col-sm-8">
              <input type="text" #datepickerYMD="bsDatepicker" bsDatepicker formControlName="actual_installed_date" class="form-control form-control-sm" id="actual_installed_date" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }">
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container formGroupName="order">
      <nz-divider nzText="Order Detail" nzOrientation="left"></nz-divider>
      <input type="text" [hidden]="true" formControlName="id" class="form-control form-control-sm" id="id">
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="order_no" class="col-sm-4 col-form-label required">Order No</label>
            <div class="col-sm-8">
              <input type="text" formControlName="order_no" class="form-control form-control-sm" id="order_no">
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="timestamp" class="col-sm-4 col-form-label">Created On</label>
            <div class="col-sm-8">
              <input type="text" [value]="asset.get('order').get('timestamp').value | date: 'yyyy-MM-dd, h:mm a'" formControlName="timestamp" class="form-control form-control-sm" id="timestamp">
            </div>
          </div>
        </div>
      </div>
    </ng-container> -->

    <!-- <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <button type="submit" [disabled]="asset.invalid" class="btn btn-primary btn-sm">Update</button>
          </div>
        </div>
      </div>
    </div> -->

  </form>
</ng-container>
